<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">
                <p>Nuevo Usuario</p>
            </div>
        </div>
        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>
            
                <form @submit.prevent="onSubmit">

                    <b-field label="Nombre">
                        <b-input v-model="form.name" required></b-input>
                    </b-field>

                    <b-field label="Apellido Paterno">
                        <b-input v-model="form.surname" required></b-input>
                    </b-field>

                    <b-field label="Apellido Materno">
                        <b-input v-model="form.lastname" required></b-input>
                    </b-field>

                    <b-field label="Email">
                        <b-input v-model="form.email" required></b-input>
                    </b-field>

                    
                    <b-field label="Contraseña" message="Guarda este dato en un lugar seguro" type="is-danger">
                        <b-field >
                            <b-input placeholder="Contraseña"
                            :value="form.password"
                            readonly
                            expanded>
                            
                        </b-input>
                        <p class="control">
                            <b-button @click="getPassword" class="is-primary">Generar</b-button>
                        </p>
                        </b-field>
                    </b-field>

                    <b-field label="Perfil">

                        <b-select v-model="form.profile" placeholder="Seleciona un perfil" required expanded>
                            <option value="admin">admin</option>
                            <option value="client">client</option>
                        </b-select>
                    </b-field>

                    <br>

                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                    </div>
                </form>
            </section>

        </div>
    </div>
    
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
import generatePassword from '../../mixins/generatePassword'

export default {
    data() {
        return {
            form: {
                name: null,
                surname: null,
                lastname: null,
                email: null,
                password: null,
                profile: null
            }
        }
    },
    mixins: [
        mixins,
        generatePassword
    ],
    beforeMount() {
        
        let vm = this

        vm.changeCancelModalUser(false)
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })

            if (data.user) {

                vm.addUser(data.user)
                vm.cancel()
                
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {
        
        ...mapMutations('user', ['changeCancelModalUser', 'changeNewModalUser', 'addUser']),
        getPassword() {

            this.form.password = this.generatePassword()
        },
        cancel() {
            this.changeCancelModalUser(true)
            this.changeNewModalUser(false)
        },
        onSubmit() {

            let vm = this

            if (vm.form.password == null) {
                   
                this.$buefy.toast.open({
                    duration: 5000,
                    message: `Debes generar una contraseña`,
                    position: 'is-bottom',
                    type: 'is-danger'
                })
                return
            }

            const data = {
                name: vm.form.name,
                surname: vm.form.surname,
                lastname: vm.form.lastname,
                email: vm.form.email,
                password: vm.form.password,
                profile: vm.form.profile
            }

            vm.sendRequest('post', 'user', data)
                
        }
    },
}
</script>