<template>
    <section class="hero is-success is-fullheight">
        <!-- <div class="hero-body"> -->
        <!-- <div class="container"> -->
        <div id="app" >

            <router-link to="/" replace></router-link>
            <router-view @logout="exit" />

        </div>
        <!-- </div> -->
        <!-- </div> -->
    </section>

</template>

<script>
import { mapActions } from 'vuex'

export default {
    data() {
        return {
            timer: null
        }
    },
    name: 'App',
    methods: {

        ...mapActions('app', ['logout']),
        exit() {
            this.logout(this)
        }
    },
}
</script>

<style>
    
    @import './assets/css/styles.css';

</style>

<style lang="scss">
    @charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
@import "~bulma/sass/utilities/_all";

// Set your colors
$primary: #005b82;
$primary-light: findLightColor($primary);
$primary-dark: findDarkColor($primary);
$primary-invert: findColorInvert($primary);
$success: #606b6c;
$success-light: findLightColor($success);
$success-dark: findDarkColor($success);
$success-invert: findColorInvert($success);

// Lists and maps
$custom-colors: null !default;
$custom-shades: null !default;

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: mergeColorMaps(
    (
        "white": (
            $white,
            $black,
        ),
        "black": (
            $black,
            $white,
        ),
        "light": (
            $light,
            $light-invert,
        ),
        "dark": (
            $dark,
            $dark-invert,
        ),
        "primary": (
            $primary,
            $primary-invert,
            $primary-light,
            $primary-dark,
        ),
        "link": (
            $link,
            $link-invert,
            $link-light,
            $link-dark,
        ),
        "info": (
            $info,
            $info-invert,
            $info-light,
            $info-dark,
        ),
        "success": (
            $success,
            $success-invert,
            $success-light,
            $success-dark,
        ),
        "warning": (
            $warning,
            $warning-invert,
            $warning-light,
            $warning-dark,
        ),
        "danger": (
            $danger,
            $danger-invert,
            $danger-light,
            $danger-dark,
        ),
    ),
    $custom-colors
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;
$navbar-dropdown-item-hover-color: $primary;
$navbar-background-color: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>