<template>
    
    <div class="card">

        <div
            class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">

                <p>Datos de {{form.username}}</p>
            </div>
            
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Nombre">
                        <b-input v-model="form.name" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Apellido Paterno">
                        <b-input v-model="form.surname" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Apellido Materno">
                        <b-input v-model="form.lastname" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Perfil">
                        <b-input v-model="form.profile" readonly required></b-input>
                    </b-field>

                    <b-field label="Email">
                        <b-input v-model="form.email" :readonly="readonly" type="email" required></b-input>
                    </b-field>

                    <b-field>
                        <b-button class="action-fhc" @click="changePassword">Cambiar Contraseña</b-button>
                    </b-field>

                    <b-field label="Estado">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="status">

                            {{form.status}}
                        </b-switch>
                    </b-field>

                    <br>

                    <div v-if="readonly" class="buttons">

                        <b-button type="is-primary" @click="toUpdate" expanded>Actualizar</b-button>
                    </div>

                    <div v-else class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Aceptar"
                            expanded/>

                        <b-button type="is-success" @click="cancel" expanded>Cancelar</b-button>
                    </div>
                </form>
            </section>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
import generatePassword from '../../mixins/generatePassword'

export default {
    data() {
        return {
            profileTypes: [1,2],
            status: null,
            readonly: true,
            disabled: true,
            form: {
                id: null,
                name: null,
                surname: null,
                lastname: null,
                email: null,
                status: null,
                profile: null
            }
        }
    },
    props: [
        'item'
    ],
    mixins: [
        mixins,
        generatePassword
    ],
    computed: {

        ...mapGetters('profile', ['activeProfiles']),
        
    },
    beforeMount() {
        
        if (this.item) {
            
            this.loadDataFromItem()
        }
    },
    watch: {
        response(data) {
            
            let vm = this
            let duration = 3000
            
            // sí regresa un cliente actualizado
            if (data.updated) {

                vm.updateUser(data.updated)
                
                vm.readonly = true
                    
            } else if (data.status) {

                this.toggleStatusUser(
                    {
                        id: this.form.id,
                        status: data.status
                    }
                )
                this.form.status = data.status
            } else {

                duration = 20000
            }

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration,
                message: data.message,
                type: 'is-success'
            })

            vm.changeCancelModalUser(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {
        
        ...mapMutations('user', ['changeCancelModalUser', 'updateUser', 'toggleStatusUser']),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.name = vm.item.Person.name
            vm.form.surname = vm.item.Person.surname
            vm.form.lastname = vm.item.Person.lastname
            vm.form.status = vm.item.status
            vm.form.email = vm.item.Person.email
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
            vm.form.profile = vm.item.profile
        },
        toUpdate() {

            this.readonly = false
            this.changeCancelModalUser(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalUser(true)
            
            if (vm.item) {
            
                vm.loadDataFromItem()

            }
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                name: vm.form.name,
                surname: vm.form.surname,
                lastname: vm.form.lastname,
                email: vm.form.email,
            }
            
            this.sendRequest('patch', 'user', data)

        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'user/toggle-status',
                {
                    id: this.form.id
                }
            )

        },
        changePassword() {
        
            this.$buefy.dialog.confirm({
                message: 'El sistema te dará una nueva contraseña. ¿Continuar?',
                onConfirm: () => this.getNewPassword(),
                onCancel: () => this.$buefy.toast.open({
                        duration: 3000,
                        message: 'No se realizó ningún cambio',
                        type: 'is-success'
                    }),
                confirmText: 'Continuar',
                cancelText: 'Cancelar'
            })
        },
        getNewPassword() {

            this.sendRequest(
                'patch',
                'user/update-password',
                {
                    id: this.form.id,
                    password: this.generatePassword()
                }
            )
            
        },
    },
}
</script>