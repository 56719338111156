<template>
    
    <div class="card">
        
        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">

                Calendario {{form.year.substring(0,4)}}
            </div>
            
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <b-tabs position="is-centered" class="block" :animated="true">

                    <b-tab-item label="Configuración" icon="cog">

                        <form @submit.prevent="onSubmit">

                            <b-field label="Año">
                                <b-input
                                    v-model="form.year"
                                    :readonly="readonly"
                                    required
                                    type="number"
                                    :min="minYear"
                                    :max="maxYear"
                                ></b-input>
                            </b-field>

                            <br>

                            <div v-if="readonly" class="buttons">

                                <b-button type="is-primary" @click="toUpdate" expanded>Actualizar</b-button>
                            </div>

                            <div v-else-if="!readonly" class="buttons">

                                <b-button
                                    :tag="tag"
                                    native-type="submit"
                                    type="is-primary"
                                    value="Aceptar"
                                    :loading="loading"
                                    expanded/>

                                <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>
                            </div>
                        </form>
                    </b-tab-item>

                    <b-tab-item label="Días festivos" icon="calendar-day">

                        <Holiday :calendarId="form.id" :year="form.year"/>
                    </b-tab-item>
                </b-tabs>
            </section>
            
        </div>
    </div>

</template>

<script>

import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
import Holiday from '@/components/holiday/Holiday.vue'

export default {
    data() {
        return {
            readonly: true,
            minYear: null,
            maxYear: null,
            form: {
                id: null,
                route: null,
                year: null
            }
        }
    },
    props: [
        'item'
    ],
    components: {
        Holiday
    },
    mixins: [
        mixins
    ],
    beforeMount() {

        let vm = this

        let d = new Date()
        vm.minYear = d.getFullYear()
        vm.maxYear = parseInt(d.getFullYear()) + 10

        if (vm.item) {
            
            vm.loadDataFromItem()
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa un cliente actualizado
            if (data.updated) {

                // actualizamos los datos en la lista
                vm.updateCalendar(data.updated)
                vm.readonly = true
                    
            }

            vm.changeCancelModalCalendar(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        }
    },
    methods: {
        
        ...mapMutations('calendar', ['changeCancelModalCalendar', 'updateCalendar', 'toggleStatusCalendar']),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.year = vm.item.year.slice(0,4)
        },
        toUpdate() {

            this.readonly = false
            this.changeCancelModalCalendar(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalCalendar(true)
            
            if (vm.item) {
            
                vm.loadDataFromItem()

            }
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                year: vm.form.year + '-01-01'
            }

            vm.sendRequest('patch', 'calendar', data)

        }
    },
}
</script>