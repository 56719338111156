<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">
                <p>Nueva asociación</p>
            </div>
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Fecha">
                        <b-datepicker
                            inline
                            :min-date="minDate"
                            :max-date="maxDate"
                            v-model="form.date"
                            :unselectable-days-of-week="unselectableDays"
                            :date-formatter="dateFormatter"      
                    >
                        </b-datepicker>
                    </b-field>

                    <b-field label="Descripción">
                        <b-input v-model="form.description" required></b-input>
                    </b-field>

                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                    </div>

                </form>
            </section>

        </div>
    </div>
   
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'

export default {
    data() {
        return {
            readonly: true,
            minDate: null,
            maxDate: null,
            unselectableDays: [0,6],
            form: {
                date: null,
                description: null
            }
        }
    },
    mixins: [
        dateFormatter,
        mixins
    ],
    props: [
        'calendarId',
        'year'
    ],
    beforeMount() {
        
        let vm = this

        vm.minDate = new Date(vm.year.slice(0,4), 0, 1)
        vm.maxDate = new Date(vm.year.slice(0,4), 11, 31)
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura nueva
            if (data.holiday) {

                vm.addHoliday(data.holiday)
                vm.cancel()
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {
        
        ...mapMutations('holiday', ['addHoliday']),
        ...mapMutations('calendar', ['changeCancelModalCalendar']),
        cancel() {
            this.changeCancelModalCalendar(true)
            this.$emit('hideForm', false)
        },
        onSubmit() {
            let vm = this

            const data = {
                date: this.dtf.format(vm.form.date),
                description: vm.form.description,
                calendarId: this.calendarId
            }

            vm.sendRequest('post', 'holiday', data)

        }
    },
    
}
</script>