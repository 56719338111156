const mixin = {

    data() {
        return {
            dft: null
        }
    },

    beforeMount() {
        
        this.dtf = new Intl.DateTimeFormat('en-CA', { timezome: 'UTC' })
    },

    methods: {
        
        dateFormatter(dt) {

            return this.dtf.format(dt)
        },

        toDateObject(stringDate) {

            return new Date(
                parseInt(stringDate.substring(0,4),10),
                parseInt(stringDate.substring(5,7),10) - 1,
                parseInt(stringDate.substring(8,10),10)
            )
        }
    }
}

export default (

    mixin
)