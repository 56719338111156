<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">
                <p>Nuevo Departamento</p>
            </div>
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Nombre">
                        <b-input v-model="form.name" required></b-input>
                    </b-field>

                    <b-field label="Descripción">
                        <b-input v-model="form.description" required></b-input>
                    </b-field>

                    <br>

                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                    </div>
                </form>
            </section>

        </div>
    </div>

</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            form: {
                name: null,
                description: null,
            }
        }
    },
    mixins: [
        mixins
    ],
    beforeMount() {
        
        this.changeCancelModalDepartment(false)
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa un departamento nuevo
            if (data.department) {

                vm.addDepartment(data.department)
                vm.cancel()
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {

        ...mapMutations('department', ['changeCancelModalDepartment', 'changeNewModalDepartment', 'addDepartment']),
        cancel() {
            this.changeCancelModalDepartment(true)
            this.changeNewModalDepartment(false)
        },
        onSubmit() {

            let vm = this

            const data = {
                name: vm.form.name,
                description: vm.form.description,
                structureId: sessionStorage.getItem('structureId')
            }

            vm.sendRequest('post', 'department', data)

        }
    },
}
</script>