<template>
    
    <div v-if="item" class="card">

        <div
            class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">

                <p>Fechas solicitadas para el aniversario {{item.validity}}</p>
            </div>
            
        </div>

        <div class="card-content">

            <section >

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <vc-calendar
                    :columns="$screens({ default: 1, lg: 4 })"
                    :rows="$screens({ default: 1, lg: 3 })"
                    :is-expanded="$screens({ default: true, lg: false })"
                    :min-date="minDate"
                    :disabled-dates="disabledDates"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                    >
                </vc-calendar>

                <br>

                <div v-if="datesToAuthorize > 0" class="buttons">
                    <b-button
                        :tag="tag"
                        type="is-primary"
                        value="Autorizar"
                        :loading="loading"
                        @click="onSubmit"
                        >
                    </b-button>
                    <b-button v-if="!loading" type="is-primary is-light" @click="resetDates">Revertir cambios</b-button>
                </div>

            </section>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'
export default {
    data() {
        return {
            datesToAuthorize: 0,
            datesAuthorizated: [],
            minDate: null,
            disabledDates: [
                { weekdays: [1, 7] }
            ],
            days: []
        }
    },
    props: [
        'item',
        'status',
        'nextStatus'
    ],
    mixins: [
        mixins,
        dateFormatter
    ],
    computed: {

        ...mapGetters('collaborator', ['getOtherVacations']),
        ...mapGetters('client', ['getOtherVacationsClient']),
        ...mapState('holiday', [ 'allHolidays']),
        ...mapState('collaborator',['infoCollaborator']),
        dates() {

            return this.days.map(day => day.date);
        },
        attributes() {
            let vm = this
            return [
                ...vm.dates.map(date => ({
                    highlight: true,
                    dates: date,
                })),
                ...vm.datesAuthorizated.map(day => ({
                    dates: vm.toDateObject(day.date),
                    dot: {
                        class: 'opacity-75'
                    },
                    popover: {
                        label: `Fecha ${day.status}, para este colaborador`,
                    },
                    customData: day,
                })),
                ...vm.getOtherVacations({
                    id: vm.item.collaboratorId,
                    aniversary: vm.item.aniversary
                }).map(vacation => ({
                    dates: vm.toDateObject(vacation.date),
                    dot: {
                        color: 'yellow',
                        class: 'opacity-75'
                    },
                    popover: {
                        label: `Fecha ${vacation.status}, para ${vacation.name}`,
                    },
                    customData: vacation,
                })),
                ...vm.getOtherVacationsClient({
                    id: vm.item.collaboratorId,
                    aniversary: vm.item.aniversary
                }).map(vacation => ({
                    dates: vm.toDateObject(vacation.date),
                    dot: {
                        color: 'yellow',
                        class: 'opacity-75'
                    },
                    popover: {
                        label: `Fecha ${vacation.status}, para ${vacation.name}`,
                    },
                    customData: vacation,
                }))
            ]
        },
    },
    beforeMount() {
        
        let vm = this
        let now = new Date()
        
        // obtiene el primer día de este año para ponerlo como fecha mínima
        vm.minDate = new Date(now.getFullYear(),0,1)

        if (vm.item.VacationDates.length > 0) {
            
            vm.item.VacationDates.forEach(day => {
                
                if (day.status === vm.status) {

                    // rellena los días con las fechas para aprobar
                    vm.days.push({
                        id: day.date,
                        date: vm.toDateObject(day.date),
                    })
                    vm.datesToAuthorize ++
                } else {

                    vm.datesAuthorizated.push(day)
                }
            })
        }
        vm.allHolidays.forEach(day => {

            vm.disabledDates.push({
                start: vm.toDateObject(day.date),
                end: vm.toDateObject(day.date)
            })
        })

    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura nueva
            if (data.updated) {

                vm.$emit('updateDates', data.updated)
                vm.changeCancelModalVacationAuthorization(true)
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {

        ...mapMutations('vacationAuthorization', ['changeCancelModalVacationAuthorization']),
        onDayClick(day) {

            let vm = this

                const idx = vm.days.findIndex(d => d.id === day.id);
    
                // si existe el index quita la fecha
                if (idx >= 0) {
    
                    vm.days.splice(idx, 1);
                } else {
                    
                    // si no sobre pasa la cantidad de fechas solicitadas
                    if (vm.days.length < vm.datesToAuthorize) {
                        vm.days.push({
                            id: day.id,
                            date: day.date,
                        });
                    } else {
                        if (vm.datesToAuthorize > 0) {
                            
                            // mandamos mensaje
                            vm.$buefy.toast.open({
                                duration: 3000,
                                message: 'No puedes agregar más días, debes quitar alguno antes de poder modificarlo',
                                type: 'is-danger'
                            })
                        } else {
                            // mandamos mensaje
                            vm.$buefy.toast.open({
                                duration: 3000,
                                message: 'No Hay fechas para hacer esta operación',
                                type: 'is-info'
                            })
                        }
                    }
                }
        },
        resetDates() {

            let vm = this
            vm.days.splice(0, vm.days.length)

            vm.item.VacationDates.forEach(day => {

                if (day.status === vm.status) {

                    // rellena los días con las fechas para aprobar
                    vm.days.push({
                        id: day.date,
                        date: vm.toDateObject(day.date),
                    })
                }
            })
        },
        onSubmit() {

            let vm = this
            
            if(vm.validateDates() < 0) {
                return
            }

            vm.changeCancelModalVacationAuthorization(false)

            const sortDates = vm.days.sort((a, b) => { return a.date.getTime() - b.date.getTime()})

            const dates = sortDates.map(date => date.id)

            const data = {
                vacationId: vm.item.id,
                dates,
                status: vm.status,
                nextStatus: vm.nextStatus
            }

            vm.sendRequest('patch', 'vacation-date/', data)

        },
        validateDates() {

            let vm = this

            if(vm.days.length !== vm.datesToAuthorize) {

                vm.$buefy.toast.open({
                    duration: 3000,
                    message: 'los días que estas autorizando son menores que los solicitados por el empleado, por favor revisa',
                    type: 'is-danger'
                })
                return -1
            }

            vm.days.forEach(day => {

                if(vm.datesAuthorizated.findIndex(date => date.date === day.id) >= 0) {
                    // mandamos mensaje
                    vm.$buefy.toast.open({
                        duration: 3000,
                        message: `La fecha ${day.id} ya se registro para este empleado anteriormente`,
                        type: 'is-danger'
                    })

                    return -1
                }

            })
            return 0
        }
  },
}
</script>