<template>
    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Tus vacacioes
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-message v-if="message" type="is-info" has-icon>
                        {{message}}
                    </b-message>

                    <!-- para el caso de que tenga vacaciones anteriores -->
                    <b-table
                        v-if="infoCollaborator.Vacations.length > 0"
                        :data="infoCollaborator.Vacations"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">
                        
                        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
                            {{ props.row.id }}
                        </b-table-column>

                        <b-table-column field="aniversary" label="Aniversario" width="40" numeric v-slot="props">
                            {{ props.row.aniversary }}
                        </b-table-column>

                        <b-table-column field="days" label="Días de vacaciones" width="40" numeric v-slot="props">
                            {{ props.row.days }}
                        </b-table-column>

                        <b-table-column field="used_days" label="Días solicitados" width="40" numeric v-slot="props">
                            {{ props.row.used_days }}
                        </b-table-column>

                        <b-table-column field="validity" label="Vigencia" width="40" numeric v-slot="props">
                            {{ props.row.validity.substring(0,10) }}
                        </b-table-column>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>
                    
                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="collaboratorVacationCancelModal">
                        
                        <FormCollaboratorVacation :item="item" :consecutiveDays="infoCollaborator.Structure.Client.Benefit.consecutiveDays"/>
                        
                    </b-modal>
                </section>

            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormCollaboratorVacation from '@/components/collaboratorVacation/FormCollaboratorVacation.vue'
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'

export default {
    data() {
        return {
            message: null,
            currentPage: 1,
            perPage: 5,
            selected: null,
            showDetails: false,
            item: null,
            ids: []
        }
    },
    mixins: [
        mixins,
        dateFormatter
    ],
    components: {
        FormCollaboratorVacation
    },
    computed: {

        ...mapState('collaborator', ['infoCollaborator', 'daysToEnjoyVacation', 'calendars']),
        ...mapState('collaboratorVacation', ['collaboratorVacationCancelModal']),
        ...mapState('holiday', [ 'allHolidays']),
        ...mapState('calendar', ['calendars'])
    },
    beforeMount() {

        let vm = this

        if (vm.daysToEnjoyVacation > 0 ) {
            vm.message = `Sólo te faltan ${vm.daysToEnjoyVacation} para disfurtar de tus siguientes vacaciones`
        } else if (vm.daysToEnjoyVacation < 0) {
            vm.message = `Faltan configuraciones para poder continuar, comunicate con el responsable de tu área`
        }
    },
    watch: {

        selected(data) {
           
            if (data !== null) {
                
                let vm = this

                vm.item = data 

                // si no hay días usados
                if (data.used_days < data.days) {
 
                    vm.getCalendars({
                        clientId: vm.infoCollaborator.Structure.Client.id,
                        vm
                    })
                    
                } else {
                    vm.enableDataForm()
                }
            }
        },
        calendars(data) {

            let vm = this

            if (data) {
                
                const year = vm.toDateObject(vm.item.aniversary)
                vm.calendars.forEach(calendar => {
                    
                    if (parseInt(calendar.year.slice(0,4)) >= year.getFullYear()) {
                        
                        vm.ids.push(calendar.id)
                    }
                })
                
                if (vm.ids.length > 0) {

                    vm.getAllHolidays({
                        ids: vm.ids,
                        vm
                    })
                }

            }
        },
        allHolidays(data) {

            if (data) {
                this.enableDataForm()
            }
        },
        showDetails(data) {
            
            if (data == false) {
                this.selected = null
            }
        }
    },
    methods: {

        ...mapActions('calendar', ['getCalendars']),
        ...mapActions('holiday', ['getAllHolidays']),
        ...mapMutations('collaboratorVacation', ['changeNewCollaboratorVacationModal']),
        enableDataForm() {

            this.changeNewCollaboratorVacationModal(true)
            this.showDetails = true
        },
    },
}
</script>