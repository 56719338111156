<template>

    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Tus Datos
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <div class="card">

                    <section>

                        <div class="card-content">

                            <section>

                                <b-field label="Fecha de alta">
                                    <b-input v-model="infoCollaborator.initial_date" readonly="readonly" type="text"></b-input>
                                </b-field>

                                <b-field label="Nombre">
                                    <b-input v-model="infoCollaborator.Person.name" readonly="readonly" type="text"></b-input>
                                </b-field>

                                <b-field label="Apellido Paterno">
                                    <b-input v-model="infoCollaborator.Person.surname" readonly="readonly" type="text"></b-input>
                                </b-field>

                                <b-field label="Apellido Materno">
                                    <b-input v-model="infoCollaborator.Person.lastname" readonly="readonly" type="text"></b-input>
                                </b-field>

                                <b-field label="E-mail">
                                    <b-input v-model="infoCollaborator.Person.email" readonly="readonly" type="text"></b-input>
                                </b-field>

                                <b-field label="Puesto">
                                    <b-input v-model="infoCollaborator.Job.name" readonly="readonly" type="text"></b-input>
                                </b-field>

                            </section>

                        </div>
                    </section>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    computed: {
        ...mapState('collaborator', ['infoCollaborator'])
    }
}
</script>