<template>

    <div class="container">

        <div v-if="multipleStructures" class="column column is-4-desktop is-offset-4-desktop">
                    
            <section class="section">

                <div class="card">

                    <div class="card-header">

                        <p class="card-header-title">
                            <em>Selección de estructura</em>
                        </p>
                    </div>

                    <div class="card-content">

                        <div class="content">

                            <b-field label="Estructura">
                                <b-select placeholder="Selecciona el año" v-model="structureId" expanded>
                                    <option
                                        v-for="option in options"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div v-else class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Departamentos de la estructura {{structureName}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingDepartments"
                        :data="departments"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left" >
                            <b-tooltip
                                v-if="options.length >1"
                                label="Regresar"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showSelectStructure"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="chevron-left">
                                </b-button>
                            </b-tooltip>
                            <b-tooltip
                                label="Registrar un nuevo departamento"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewDepartmentForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingDepartments" :can-cancel="false"></b-loading>
                    </b-notification>
                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalDepartment">
                        
                        <FormDepartment :item="selected" />
                        
                    </b-modal>
                </section>

                <!-- modal para registrar este módulo a un cliente-->
                <section>

                    <b-modal :active.sync="modalFormDepartment" :width="640" scroll="keep" :can-cancel="cancelModalDepartment">
                        
                        <FormNewDepartment />
                    </b-modal>
                </section>

            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import FormDepartment from '@/components/department/FormDepartment.vue'
import FormNewDepartment from '@/components/department/FormNewDepartment.vue'

export default {
    data() {
        return {
            showDetails: false,
            multipleStructures: false,
            options: [],
            structureId: null,
            structureName: null,
            currentPage: 1,
            perPage: 5,
            selected: null,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'name',
                    label: 'Nombre',
                    searchable: true
                }
            ]
        }
    },
    components: {

        FormDepartment,
        FormNewDepartment
    },
    computed: {

        ...mapState('structure', ['structures']),
        ...mapGetters('structure', ['avaliableStructures']),
        ...mapState('department',
            [
                'loadingDepartments',
                'departments',
                'cancelModalDepartment',
                'modalFormDepartment'
            ]
        )
    },
    beforeMount() {

        this.getStructures({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })

    },
    watch: {
        structures: function(data) {

            if (data !== null) {

                this.options = this.avaliableStructures('Activo')

                if (this.options.length > 1) {
                    
                    this.multipleStructures = true
                }
                else {
                    this.structureId = this.options[0].id
                }
            }

        },
        structureId: function(value) {
            
            if (value !== null) {
                
                sessionStorage.setItem('structureId', value)
                this.options.forEach(element => {
                    if (element.id === value) {
                        this.structureName = element.name
                        return
                    }
                });
                this.multipleStructures = false
                this.getDepartments({
                    structureId: this.structureId,
                    vm: this
                })
            }
        },
        selected: function(data) {

            if (data !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {
                
                this.selected = null
            }
        }
    },
    methods: {
        
        ...mapActions('structure', ['getStructures']),
        ...mapActions('department', ['getDepartments']),
        ...mapMutations('department', ['changeNewModalDepartment']),
        showSelectStructure() {
            this.multipleStructures = true
        },
        showNewDepartmentForm() {
            
            this.changeNewModalDepartment(true)
        }
    },
}
</script>