<template>
    
    <div class="card">

        <div
            class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">

                <p>Datos del collaborador</p>
            </div>
            
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Fecha de alta">
                        <b-datepicker
                            placeholder="Click para seleccionar..."
                            v-model="form.initial_date"
                            :date-formatter="dateFormatter"      
                            :disabled="readonly"
                        >
                        </b-datepicker>
                    </b-field>

                    <b-field label="Unidad de Negocio">
                            <b-select v-model="form.branchOfficeId" :disabled="readonly" required expanded>
                                
                                <option
                                    v-for="option in getAvaliableBranchOffices('Activo')"
                                    :value="option.id"
                                    :key="option.id">
                                    {{ option.name}}
                                </option>
                            </b-select>
                        </b-field>

                    <b-field label="Tipo de colaborador">
                            <b-select v-model="form.collaboratorTypeId" :disabled="readonly" required expanded>
                                
                                <option
                                    v-for="option in getAvaliableCollaboratorTypes('Activo')"
                                    :value="option.id"
                                    :key="option.id">
                                    {{ option.name}}
                                </option>
                            </b-select>
                        </b-field>

                        <b-field v-if="authorizers > 1" label="¿Autoriza vacaciones de subordinados?">
                            <b-switch
                                :rounded="false"
                                type="is-primary"
                                v-model="form.authorizing"
                                :disabled="readonly">

                            </b-switch>
                        </b-field>

                        <b-field label="Departamento">
                            <b-select v-model="departmentId" :disabled="readonly" required expanded>
                                <option
                                    v-for="option in getAvaliableDepartments('Activo')"
                                    :value="option.id"
                                    :key="option.id">
                                    {{ option.name}}
                                </option>
                            </b-select>
                        </b-field>

                    <b-field label="Puesto">
                        <b-select placeholder="Selecciona el puesto" v-model="form.jobId" :disabled="readonly" expanded>
                            <option
                                v-for="option in jobs"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Tiene Jefe o Autorizador">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="hasBoss"
                            :disabled="readonly">

                        </b-switch>
                    </b-field>

                    <b-field v-if="getBosses.length > 0 && hasBoss" label="Jefe o Autorizador">
                        <b-select placeholder="Selecciona un colaborador" v-model="form.parent_id" :disabled="readonly" expanded>
                            <option
                                v-for="option in getBosses"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Email">
                        <b-input v-model="form.email" :readonly="readonly" type="email" required></b-input>
                    </b-field>

                    <b-field label="Nombre">
                        <b-input v-model="form.name" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Apellido Paterno">
                        <b-input v-model="form.surname" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Apellido Materno">
                        <b-input v-model="form.lastname" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field>
                        <b-button class="action-fhc" @click="changePassword">Cambio de contraseña</b-button>
                    </b-field>

                    <b-field label="Estado">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="status">

                            {{form.status}}
                        </b-switch>
                    </b-field>

                    <br>

                    <div v-if="readonly" class="buttons">

                        <b-button type="is-primary" @click="toUpdate" expanded>Actualizar</b-button>
                    </div>

                    <div v-else class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Aceptar"
                            :loading="loading" 
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>
                    </div>

                </form>
            </section>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'
import generatePassword from '../../mixins/generatePassword'

export default {
    data() {
        return {
            status: false,
            readonly: true,
            departmentId: null,
            hasBoss: false,
            form: {
                id: null,
                authorizing: false,
                branchOfficeId: null,
                collaboratorTypeId: null,
                initial_date: null,
                jobId: null,
                username: null,
                email: null,
                name: null,
                surname: null, 
                lastname: null,
                parent_id: null,
                status: null
            }
        }
    },
    mixins: [
        mixins,
        dateFormatter,
        generatePassword
    ],
    props: [
        'item',
        'authorizers'
    ],
    beforeMount() {
        
        if (this.item) {
            this.loadDataFromItem()
        }
    },
    computed: {
        
        ...mapGetters('collaboratorType', ['getAvaliableCollaboratorTypes']),
        ...mapGetters('branchOffice', ['getAvaliableBranchOffices']),
        ...mapGetters('department', ['getAvaliableDepartments']),
        ...mapState('department', ['departments']),
        ...mapState('job', ['jobs']),
        ...mapGetters('collaborator', ['getBosses'])
    },
    watch: {
        response(data) {
            
            let vm = this

            let duration = 3000

            // si se actualiza el departamento
            if (data.updated) {
                
                vm.updateCollaborator(data.updated)
                vm.readonly = true
            }
            // si se actualizó el estado
            else if (data.status) {

                vm.toggleStatusCollaborator(
                    {
                        id: vm.form.id,
                        status: data.status
                    }
                )
                vm.form.status = data.status
            } else if (data.flag) {

                duration = 7000
            }

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration,
                message: data.message,
                type: 'is-success'
            })

            vm.changeCancelModalCollaborator(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        departmentId: function(value) {

            if (value != null) {
                this.getJobs(
                    {
                        departmentId: value,
                        vm: this
                    }
                )
            }
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {

        ...mapMutations('collaborator', ['changeCancelModalCollaborator', 'updateCollaborator', 'toggleStatusCollaborator']),
        ...mapActions('job', ['getJobs']),
        loadDataFromItem() {

            let vm = this
            
            vm.form.id                      = vm.item.id
            vm.form.collaboratorTypeId      = vm.item.collaboratorTypeId
            vm.form.branchOfficeId          = vm.item.branchOfficeId
            vm.form.authorizing             = vm.item.authorizing
            vm.departmentId                 = vm.item.Job.DepartmentId
            vm.form.jobId                   = vm.item.jobId
            vm.form.email                   = vm.item.Person.email
            vm.form.name                    = vm.item.Person.name
            vm.form.surname                 = vm.item.Person.surname
            vm.form.lastname                = vm.item.Person.lastname
            vm.form.status                  = vm.item.status
            vm.form.initial_date            =  new Date(
                                                vm.item.initial_date.slice(0,4),
                                                (parseInt(vm.item.initial_date.slice(5,7))-1).toString(),
                                                vm.item.initial_date.slice(8,10)
                                            )
            if (vm.item.bossId > 0) {
                vm.hasBoss = true
            }
            
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
            

        },
        toUpdate() {

            let vm =this
            vm.readonly = false
            vm.changeCancelModalCollaborator(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalCollaborator(true)
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                collaboratorTypeId: vm.form.collaboratorTypeId,
                branchOfficeId: vm.form.branchOfficeId,
                authorizing: vm.form.authorizing,
                jobId: vm.form.jobId,
                initial_date: vm.dtf.format(vm.form.initial_date),
                email: vm.form.email,
                name: vm.form.name,
                surname: vm.form.surname,
                lastname: vm.form.lastname,
                parentId: vm.hasBoss === false ? 0 : vm.form.parentId
            }

            vm.sendRequest('patch', 'collaborator', data)

        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'collaborator/toggle-status',
                {
                    id: this.form.id
                }
            )

        },
        changePassword() {

            this.$buefy.dialog.confirm({
                message: 'El sistema te dará una nueva contraseña. ¿Continuar?',
                onConfirm: () => this.getNewPassword(),
                onCancel: () => this.$buefy.toast.open({
                        duration: 3000,
                        message: 'No se realizó ningún cambio',
                        type: 'is-success'
                    }),
                confirmText: 'Continuar',
                cancelText: 'Cancelar'
            })
        },
        getNewPassword() {

            this.sendRequest(
                'patch',
                'collaborator/change-password',
                {
                    id: this.form.id,
                    password: this.generatePassword()
                }
            )

        },
    },

}
</script>