<template>
    <div class="columns">
        
        <div class="column column is-4-desktop is-offset-1-desktop">
        
            <section class="section">

                <div class="card">

                    <div class="card-content">

                        <section class="section">
                            <img alt="FHC logo" src="../assets/images/logo_FHC.png">
                        </section>
                        
                        <section class="section">
                            
                            <form @submit.prevent="onSubmit" id="frm_login">

                                <b-field>
                                    <b-input
                                        placeholder="Email"
                                        type="email"
                                        icon-pack="fas"
                                        icon="envelope"
                                        v-model="form.email"
                                        required>
                                    </b-input>
                                </b-field>

                                <b-field>
                                    <b-input
                                        placeholder="Password reveal input"
                                        type="password"
                                        icon-pack="fas"
                                        icon="lock"
                                        password-reveal
                                        v-model="form.password"
                                        required>
                                    </b-input>
                                </b-field>

                                <div class="buttons">
                                    <b-button
                                        :tag="tag"
                                        native-type="submit"
                                        value="Entrar"
                                        type="is-primary"
                                        :loading="loading"
                                        expanded
                                    />
                                    
                                </div>
                            
                            </form>

                        </section>

                    </div>
                    
                </div>

            </section>

        </div>
        

    </div>
        
</template>

<script>

import Axios from 'axios'
import { mapState } from "vuex"

export default {
    data() {
        return {
            loading: false,
            tag: 'input',
            form: {
                email: null,
                password: null
            }
        }
    },
    computed: {
        ...mapState('app', ['path'])
    },
    beforeMount() {
        sessionStorage.clear()
    },
    methods: {
        onSubmit() {
            
            let vm = this

            vm.loading = true
            vm.tag = 'button'

            Axios.post(
                vm.path + 'login',
                {
                    email: vm.form.email,
                    password: vm.form.password
                }
            )
            .then((response) => {
                
                const {data} = response
                sessionStorage.setItem('user-token', data.token)
                sessionStorage.setItem('user-username', data.user.Person.name)
                sessionStorage.setItem('user-email', data.user.Person.email)
                sessionStorage.setItem('user-id', data.user.id)
                let profile = data.user.profile
                sessionStorage.setItem('profile', profile)
                // genero variables de tiempo
                sessionStorage.setItem('inactivity',0)
                sessionStorage.setItem('timer',0)

                vm.validateProfile(profile)

            })
            .catch((error) => {
                
                const {status} = error.response
                if (status == 401) {

                    vm.$buefy.toast.open({
                        duration: 5000,
                        message: `Datos incorectos, favor de revisar`,
                        type: 'is-danger'
                    })
                    
                }

            })
            .finally(() => {
                
                // reset de formulario
                for (const prop in vm.form) {
                    vm.form[prop] = null
                }
                vm.loading = false
                vm.tag = 'input'

            })

        },
        validateProfile(profile) {

            let vm = this

            switch (profile) {
                case 'admin':
                    vm.$router.replace({ path: "/homeAdmin" })
                    break;
                
                case 'client':
                    this.$router.replace({ path: "/homeClient" })
                    break;
            
                case 'user':
                    vm.$router.replace({ path: "/homeCollaborator" })
                    break;
            }
            
        }
    },
}
</script>