<template>

    <div class="card" >

        <div class="card-content">

            <div class="content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>

                    <form @submit.prevent="onSubmit">

                        <b-field grouped>

                            <b-field label="De" expanded>
                                <b-input type="number" v-model="form.initialYear" required></b-input>
                            </b-field>
                            
                            <b-field label="A" expanded>
                                <b-input type="number" v-model="form.finalYear" required></b-input>
                            </b-field>
                            
                            <b-field label="Días" expanded>
                                <b-input type="number" v-model="form.days" required></b-input>
                            </b-field>

                        </b-field>

                        <div v-if="item !== null" class="buttons">

                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Actualizar"
                                :loading="loading"
                                expanded/>

                            <b-button v-if="!loading" type="is-success" @click="cancel('update')" expanded>Cancelar</b-button>

                        </div>
                    </form>
                </section>

            </div>
        </div>
    </div>
    
</template>

<script>
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            form: {
                id: null,
                initialYear: null,
                finalYear: null,
                days: null
            }
        }
    },
    props: [
        'item'
    ],
    mixins: [
        mixins
    ],
    beforeMount() {
        
        let vm = this

        if (vm.item !== null) {
            
            vm.form.id = vm.item.id
            vm.form.initialYear = vm.item.initialYear
            vm.form.finalYear = vm.item.finalYear
            vm.form.days = vm.item.days
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa un día actualizado
            if (data.updated) {

                vm.$emit('updateData', data.updated)
                    
            }
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        }
    },
    methods: {
        
        cancel(type) {
            this.$emit('hideForm',type)
        },
        onSubmit() {

            let vm = this

            vm.loading = true
            vm.tag = 'button'

            const data = {
                id: vm.form.id,
                initialYear: vm.form.initialYear,
                finalYear: vm.form.finalYear,
                days: vm.form.days

            }

            vm.sendRequest('patch', 'vacation-day', data)

        }
    },
}
</script>