<template>
    
    <div class="card">

            <div
                class="card-header header-fhc">

                <div class="card-header-title pading-left text-white">

                    <p>Datos del día festivo</p>
                </div>
                
            </div>

            <div class="card-content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>

                    <form @submit.prevent="onSubmit">

                        <b-field label="Fecha">
                            <b-datepicker
                                placeholder="Click para seleccionar..."
                                :min-date="minDate"
                                :max-date="maxDate"
                                v-model="form.date"
                                :date-formatter="dateFormatter"      
                                :disabled="readonly"
                                :unselectable-days-of-week="unselectableDays"
                        >
                            </b-datepicker>
                        </b-field>

                        <b-field label="Descripción">
                            <b-input v-model="form.description" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Estado">
                            <b-switch
                                :rounded="false"
                                type="is-primary"
                                v-model="status">

                                {{form.status}}
                            </b-switch>
                        </b-field>

                        <br>

                        <div v-if="readonly" class="buttons">

                            <b-button type="is-primary" @click="toUpdate" expanded>Actualizar</b-button>
                            <b-button type="is-success" @click="closeDetails" expanded>regresar</b-button>
                        </div>

                        <div v-else class="buttons">

                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Aceptar"
                                :loading="loading"
                                expanded/>

                            <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>
                        </div>

                    </form>
                </section>

            </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'

export default {
    data() {
        return {
            status: false,
            readonly: true,
            minDate: null,
            maxDate: null,
            unselectableDays: [0,6],
            form: {
                id: null,
                date: null, 
                description: null, 
                status: null
            }
        }
    },
    mixins: [
        mixins,
        dateFormatter
    ],
    props: [
        'year',
        'item'
    ],
    beforeMount() {
        
        let vm = this

        if (vm.item) {
            
            vm.loadDataFromItem()
            vm.minDate = new Date(vm.year.slice(0,4), 0, 1)
            vm.maxDate = new Date(vm.year.slice(0,4), 11, 31)
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            if (data.updated) {

                vm.updateHoliday(data.updated)
                vm.readonly = true
                
            }
            // si se actualizó el estado
            else if (data.status) {

                vm.toggleStatusHoliday(
                    {
                        id: vm.form.id,
                        status: data.status
                    }
                )
                vm.form.status = data.status
            }

            vm.changeCancelModalCalendar(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {
        
        ...mapMutations('holiday', ['updateHoliday', 'toggleStatusHoliday']),
        ...mapMutations('calendar', ['changeCancelModalCalendar']),
        loadDataFromItem() {

            let vm = this

            vm.form.id =            vm.item.id
            vm.form.date =          new Date(
                    vm.item.date.slice(0,4),
                    (parseInt(vm.item.date.slice(5,7))-1).toString(),
                    vm.item.date.slice(8,10)
                )
            vm.form.description =   vm.item.description
            vm.form.status =        vm.item.status
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
        },
        closeDetails() {

            this.$emit('hideDetails')
        },
        toUpdate() {

            let vm =this
            vm.readonly = false
            vm.changeCancelModalCalendar(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalCalendar(true)
            
            if (vm.item) {
            
                vm.loadDataFromItem()

            }
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                date: vm.dtf.format(vm.form.date),
                description: vm.form.description
            }

            vm.sendRequest('patch', 'holiday', data)

        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'holiday/toggle-status',
                {
                    id: this.form.id
                }
            )

        }
    },
}
</script>