<template>
    
    <div class="container">

        <transition name="slide-fade" mode="out-in">

            <div v-if="!showDetails && !showForm">

                <section>

                    <b-table
                        :data="holidays"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">

                            <b-tooltip
                                label="Registrar un nuevo día festivo"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewHolidayForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                        <b-table-column field="id" label="ID" width="40" numeric v-slot="props">
                            {{ props.row.id}}
                        </b-table-column>

                        <b-table-column field="date" label="Fecha" numeric v-slot="props">
                            {{ props.row.date.slice(0,10)}}
                        </b-table-column>

                        <b-table-column field="date" label="Descripción" numeric v-slot="props">
                            {{ props.row.description}}
                        </b-table-column>

                    </b-table>

                </section>
            </div>

            <!-- modal para mostrar datos del módulo -->
            <div v-else-if="showDetails">
                <section>

                    <FormHoliday @hideDetails="hideDetails" :calendarId="calendarId" :year="year" :item="selected" />
                </section>
            </div>

            <!-- modal para registrar este módulo a un cliente-->
            <div v-else-if="showForm">
                <section>

                    <FormNewHoliday
                        :calendarId="calendarId"
                        :year="year"
                        @hideForm="showForm = $event"    
                    />
                </section>
            </div>
        </transition>

    </div>
</template>

<script>

import { mapMutations, mapState } from 'vuex'
import FormHoliday from '@/components/holiday/FormHoliday.vue'
import FormNewHoliday from '@/components/holiday/FormNewHoliday.vue'

export default {
    data() {
        return {
            showDetails: false,
            showForm: false,
            showTable: true,
            moduleId: null,
            currentPage: 1,
            perPage: 5,
            selected: null,
            multipleYears: false
        }
    },
    props: [
        'calendarId',
        'year'
    ],
    components: {
        FormHoliday,
        FormNewHoliday
    },
    computed: {
        
        ...mapState('holiday', [ 'holidays', 'cancelModalHoliday', 'modalFormHoliday'])
    },
    watch: {
        selected: function(data) {

            if (data !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {
                this.selected = null
            }
        }
    },
    methods: {
        
        ...mapMutations('calendar', ['changeCancelModalCalendar']),
        showNewHolidayForm() {
            this.changeCancelModalCalendar(false)
            this.showForm = true
        },
        hideDetails() {

            let vm = this
            vm.showDetails = false
            vm.selected = null
        },
    }
}
</script>