<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title">

                <div class="card-header-title pading-left text-white">

                    Datos del tipo de collaborador {{form.name}}
                </div>
            </div>
            
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>
                
                <form @submit.prevent="onSubmit">

                    <b-field label="Nombre">
                        <b-input v-model="form.name" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field label="Descripción">
                        <b-input v-model="form.description" :readonly="readonly" required></b-input>
                    </b-field>

                    <b-field v-if="item" label="Estado">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="status">

                            {{form.status}}
                        </b-switch>
                    </b-field>

                    <br>

                    <div v-if="readonly" class="buttons">
                    
                        <b-button
                            type="is-primary"
                            @click="toUpdate"
                            expanded>

                            Actualizar
                        </b-button>
                    </div>
                        
                    <div v-else class="buttons">
                        
                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Aceptar"
                            expanded/>

                        
                        <b-button
                            @click="cancel"
                            type="is-success"
                            expanded>

                            Cancelar
                        </b-button>
                    </div>

                </form>
            </section>

        </div>

    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
export default {
    data() {
        return {
            readonly: true,
            loading: false,
            status: false,
            form: {
                name: null,
                description: null,
                status: 'Activo'
            }
        }
    },
    mixins: [
        mixins
    ],
    props: [
        'item'
    ],
    beforeMount() {

        let vm = this
        
        if (vm.item) {
            vm.loadDataFromItem()
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // si se actualiza el departamento
            if (data.updated) {
                
                vm.updateCollaboratorType(data.updated)
                vm.readonly = true
            }
            // si se actualizó el estado
            else if (data.status) {

                vm.toggleStatusCollaboratorType(
                    {
                        id: vm.form.id,
                        status: data.status
                    }
                )
                vm.form.status = data.status
            }

            vm.changeCancelModalCollaboratorType(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {

        ...mapMutations('collaboratorType', ['changeCancelModalCollaboratorType', 'updateCollaboratorType', 'toggleStatusCollaboratorType']),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.name = vm.item.name
            vm.form.description = vm.item.description
            vm.form.status = vm.item.status
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
            
        },
        toUpdate() {

            let vm =this
            vm.readonly = false
            vm.changeCancelModalCollaboratorType(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalCollaboratorType(true)
            
            vm.loadDataFromItem()
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                name: vm.form.name,
                description: vm.form.description
            }

            vm.sendRequest('patch', 'collaborator-type', data)

        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'collaborator-type/toggle-status',
                {
                    id: this.form.id
                }
            )

        }
    }
}
</script>