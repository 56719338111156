<template>
    
    <div class="container">

        <div v-if="multipleStructures" class="column column is-4-desktop is-offset-4-desktop">
                    
            <section class="section">

                <div class="card">

                    <div class="card-header">

                        <p class="card-header-title">
                            <em>Selección de estructura</em>
                        </p>
                    </div>

                    <div class="card-content">

                        <div class="content">

                            <b-field label="Estructura">
                                <b-select placeholder="Selecciona el año" v-model="structureId" expanded>
                                    <option
                                        v-for="option in options"
                                        :value="option.id"
                                        :key="option.id">
                                        {{ option.name }}
                                    </option>
                                </b-select>
                            </b-field>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div v-else class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Collaboradores de la estructura {{structureName}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingCollaborators"
                        :data="collaborators"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">

                            <b-tooltip
                                v-if="options.length >1"
                                label="Regresar"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showSelectStructure"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="chevron-left">
                                </b-button>
                            </b-tooltip>
                            
                            <b-tooltip
                                label="Registrar un nuevo colaborador"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewCollaboratorForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingCollaborators" :can-cancel="false"></b-loading>
                    </b-notification>
                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalCollaborator">
                        
                        <FormCollaborator
                            :item="selected"
                            :authorizers="authorizers"
                            />
                        
                    </b-modal>
                </section>

                <!-- modal para registrar colaborador-->
                <section>

                    <b-modal :active.sync="modalFormCollaborator" :width="640" scroll="keep" :can-cancel="cancelModalCollaborator">
                        
                        <FormNewCollaborator
                            :authorizers="authorizers"
                            />
                    </b-modal>
                </section>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import FormCollaborator from '@/components/collaborator/FormCollaborator.vue'
import FormNewCollaborator from '@/components/collaborator/FormNewCollaborator.vue'

export default {
    data() {
        return {
            options: [],
            multipleStructures: false,
            structureId: null,
            structureName: null,
            showDetails: false,
            currentPage: 1,
            perPage: 5,
            selected: null,
            structureConf: null,
            authorizers: null,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'Person.name',
                    label: 'Nombre',
                    searchable: true
                },
                {
                    field: 'Person.surname',
                    label: 'Apellido Paterno',
                    searchable: true
                },
                {
                    field: 'Person.lastname',
                    label: 'Apellido Materno',
                    searchable: true
                }
            ]
        }
    },
    components: {
        FormCollaborator,
        FormNewCollaborator
    },
    beforeMount() {

        this.getStructures({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })
        
    },
    computed: {

        ...mapState('collaborator', ['collaborators', 'loadingCollaborators', 'cancelModalCollaborator', 'modalFormCollaborator']),
        ...mapState('structure', ['structures']),
        ...mapGetters('structure', ['avaliableStructures']),
        ...mapState('department', ['departments']),
        ...mapState('collaboratorType', ['collaboratorTypes']),
        ...mapState('branchOffice', ['branchOffices']),
        ...mapState('benefit', ['benefits'])
    },
    watch: {
        structures: function(data) {

            if (data !== null) {

                this.options = this.avaliableStructures('Activo')

                if (this.options.length > 1) {
                    
                    this.multipleStructures = true
                }
                else {
                    this.structureId = this.options[0].id
                }
            }

        },
        structureId: function(value) {

            let vm = this
            
            if (value !== null) {
                
                sessionStorage.setItem('structureId', value)
                vm.options.forEach(element => {
                    if (element.id === value) {
                        vm.structureName = element.name
                        return
                    }
                });
                vm.multipleStructures = false
                vm.getCollaborators({
                    structureId: vm.structureId,
                    vm
                })
            }
        },
        selected: function(data) {

            if (data !== null) {
                
                let vm = this
                vm.getBranchOffices({
                    clientId: sessionStorage.getItem('clientId'),
                    vm
                })
            }
        },
        branchOffices: function (data) {

            let vm = this

            if (data !== null) {
                vm.getCollaboratorTypes({
                    clientId: sessionStorage.getItem('clientId'),
                    vm
                })
            }
        },
        collaboratorTypes: function(data) {
            
            if (data !== null) {
                this.getDepartments({
                    structureId: this.structureId,
                    vm: this
                })
            }
        },
        departments(data) {

            if (data !== null) {
                
                this.getBenefits({
                    clientId: sessionStorage.getItem('clientId'),
                    vm: this
                })
            }
        
        },
        benefits(data) {

            if (data !== null) {

                this.authorizers = data[0].authorizers
                this.selected !== null?                
                this.showDetails = true: this.changeNewModalCollaborator(true)
            }
        },
        showDetails: function(value) {

            if (value === false) {

                this.selected = null
            }
        },

    },
    methods: {
        
        ...mapActions('structure', ['getStructures']),
        ...mapMutations('collaborator', ['changeNewModalCollaborator']),
        ...mapActions('collaborator', ['getCollaborators']),
        ...mapActions('department', ['getDepartments']),
        ...mapActions('collaboratorType', ['getCollaboratorTypes']),
        ...mapActions('branchOffice', ['getBranchOffices']),
        ...mapActions('benefit', ['getBenefits']),
        showSelectStructure() {
            this.multipleStructures = true
        },
        showNewCollaboratorForm() {

            let vm = this

            vm.getBranchOffices({
                clientId: sessionStorage.getItem('clientId'),
                vm
            })
        }
    },
}
</script>