<template>

    <section>

        <div class="card">

            <div class="card-header header-fhc">

                <div class="card-header-title">

                    <div class="card-header-title pading-left text-white">

                        Datos de la estructura {{form.name}}
                    </div>
                </div>
                
            </div>

            <div class="card-content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>

                    <form v-else @submit.prevent="onSubmit">
                                
                        <b-field label="Nombre de la estructura">
                            <b-input v-model="form.name" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Descripción">
                            <b-input v-model="form.description" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Estado">
                            <b-switch
                                :rounded="false"
                                type="is-primary"
                                v-model="status">

                                {{form.status}}
                            </b-switch>
                        </b-field>

                        <div v-if="readonly" class="buttons">
                    
                            <b-button
                                type="is-primary"
                                @click="toUpdate"
                                expanded>

                                Actualizar
                            </b-button>
                        </div>
                            
                        <div v-else class="buttons">
                            
                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Aceptar"
                                expanded/>

                            
                            <b-button
                                @click="cancel"
                                type="is-success"
                                expanded>

                                Cancelar
                            </b-button>
                        </div>

                    </form>
                </section>
            </div>
        </div>

    </section>
</template>

<script>

import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            readonly: true,
            status: null,
            form: {
                id: null,
                name: null,
                description: null,
                client_id: null,
                status: 'Activo'
            }
        }
    },
    mixins: [
        mixins
    ],
    props: [
        'item'
    ],
    beforeMount() {

        let vm = this
        
        if (vm.item) {

            vm.loadDataFromItem()
            
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura actualizada
            if (data.updated) {

                vm.updateStructure(data.updated)
                
                vm.readonly = true
                    
            }
            // si se actualizó el estado
            else if (data.status) {

                vm.toggleStatusStructure(
                    {
                        id: vm.form.id,
                        status: data.status
                    }
                )
                vm.form.status = data.status
            }

            vm.changeCancelModalStructure(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {
        ...mapMutations('structure', ['toggleStatusStructure', 'updateStructure', 'changeCancelModalStructure']),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.name = vm.item.name
            vm.form.description = vm.item.description
            vm.form.status = vm.item.status
            vm.form.client_id = vm.item.ClientId
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
        },
        toUpdate() {
            this.readonly = false
        },
        cancel() {
            this.readonly = true
        },
        onSubmit() {
            
            let vm = this

            const data = {
                id: vm.form.id,
                name: vm.form.name,
                description: vm.form.description,
            }

            vm.sendRequest('patch', 'structure', data)
        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'structure/toggle-status',
                {
                    id: this.form.id
                }
            )

        }
    }
}
</script>