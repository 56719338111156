<template>

        <div class="card">

            <div
                v-if="item"
                class="card-header header-fhc">

                <div class="card-header-title pading-left text-white">

                    <p v-if="!item.trademark">Cliente {{item.name}} {{item.surname}} {{item.lastname}}</p>
                    <p v-else>Cliente {{item.trademark.trademark}}</p>
                </div>
                
            </div>

            <div
                v-else
                class="card-header header-fhc">

                <p class="card-header-title pading-left text-white">
                    Nuevo Cliente
                </p>
            </div>

            <div class="card-content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>
                    
                    <form @submit.prevent="onSubmit">

                        <b-field v-if="item" label="Tipo">
                            <b-input v-model="form.type" readonly required></b-input>
                        </b-field>

                        <b-field v-else label="Tipo">
                            <b-select placeholder="Select a character" v-model="form.type" expanded required>
                                <option value="Fisica">Fisica</option>
                                <option value="Moral">Moral</option>
                            </b-select>
                        </b-field>

                        <b-field v-if="(item && form.type == 'Física') || form.type == 'Física'" label="CURP">
                            <b-input
                                v-model="form.curp"
                                :readonly="readonly"
                                minlength="18" maxlength="18" 
                                required
                                type="text"
                                validation-message="Debe cumplir el formato XXXX000000XXXXXX00"
                                :pattern="curpPattern">
                            </b-input>
                        </b-field>

                        <b-field v-if="(item && form.type == 'Moral') || form.type == 'Moral'" label="Razón Social">
                            <b-input
                                v-model="form.trademark"
                                :readonly="readonly"
                                required
                                type="text"
                                :pattren="textPattern"
                            ></b-input>
                        </b-field>

                        <b-field label="Registro Patronal">
                            <b-input v-model="form.employerRegister" :readonly="readonly" minlength="11" maxlength="11"  required></b-input>
                        </b-field>

                        <b-field label="Nombre">
                            <b-input v-model="form.name" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Apellido Paterno">
                            <b-input v-model="form.surname" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Apellido Materno">
                            <b-input v-model="form.lastname" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="RFC">
                            <b-input v-model="form.rfc" :readonly="readonly" minlength="12" maxlength="13" required></b-input>
                        </b-field>

                        <b-field label="Email">
                            <b-input v-model="form.email" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field v-if="item" label="Estado">
                            <b-switch
                                :rounded="false"
                                type="is-primary"
                                v-model="status">

                                {{form.status}}
                            </b-switch>
                        </b-field>

                        <hr>
                        
                        <div v-if="readonly" class="buttons">
                        
                            <b-button
                                type="is-primary"
                                @click="toUpdate"
                                expanded>

                                Actualizar
                            </b-button>
                        </div>
                            
                        <div v-else-if="!readonly && item" class="buttons">
                            
                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Aceptar"
                                expanded/>

                            
                            <b-button
                                @click="cancel"
                                type="is-success"
                                expanded>

                                Cancelar
                            </b-button>
                        </div>

                        <div v-else-if="!readonly && !item" class="buttons">

                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Guardar"
                                expanded/>

                            <b-button v-if="!loading" type="is-success" @click="cancelStore" expanded>Cancelar</b-button>

                        </div>

                    </form>
                </section>

            </div>
        </div>

</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import mixins from '../../mixins/mixins'
import generatePassword from '../../mixins/generatePassword'

export default {
    name:'FormClient',
    data() {
        return {
            readonly: true,
            status: false,
            form: {
                id: null,
                type: null,
                name:null,
                surname: null,
                lastname: null,
                rfc: null,
                curp: null,
                trademark: null,
                email: null,
                status: 'Activo',
                employerRegister: null
            }
        }
    },
    mixins: [
        mixins,
        generatePassword
    ],
    props: [
        'item'
    ],
    beforeMount() {

        let vm = this

        if (vm.item) {
            
            vm.loadDataFromItem()
        } else {
            vm.readonly = false
            vm.changeCancelModalClient(false)
        }
    },
    computed: {
        ...mapState('app', ['curpPattern', 'textPattern']),
    },
    watch: {

        response(data) {
            
            let vm = this
            let duration = 3000
            
            // sí regresa un cliente actualizado
            if (data.updated) {

                vm.updateClient(data.updated)
                duration = 20000
                vm.readonly = true
                    
            } else if (data.client) {

                vm.addClient(data.client)
                vm.changeNewModalClient(false)
                
            } else if (data.status) {

                this.toggleStatusClient(
                    {
                        id: this.form.id,
                        status: data.status
                    }
                )
                this.form.status = data.status
            }

            // manda mensaje
            vm.$buefy.toast.open({
                duration,
                message: data.message,
                type: 'is-success'
            })

            vm.changeCancelModalClient(true)
        },
        errors(data) {
        
            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {
        
        ...mapActions('client', ['getClients']),
        ...mapMutations(
            'client',
            [
                'changeNewModalClient',
                'changeCancelModalClient',
                'updateClient',
                'toggleStatusClient',
                'addClient'
            ]
        ),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.type = vm.item.type
            vm.form.name = vm.item.Person.name
            vm.form.surname = vm.item.Person.surname
            vm.form.lastname = vm.item.Person.lastname
            vm.form.rfc = vm.item.rfc
            vm.form.email = vm.item.Person.email
            vm.form.status = vm.item.status
            vm.form.employerRegister = vm.item.employerRegister
            if (vm.item.curp) {
                
                vm.form.curp = vm.item.curp
            }
            if (vm.item.trademark) {
                
                vm.form.trademark = vm.item.trademark
            }
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
            
        },
        toUpdate() {
            
            let vm = this
            vm.readonly = false
            vm.changeCancelModalClient(false)
        },
        cancel() {

            let vm =this
            vm.readonly = true
            vm.changeCancelModalClient(true)
            
            if (vm.item) {
            
                vm.loadDataFromItem()

            }
        },
        cancelStore() {

            this.changeCancelModalClient(true)
            this.changeNewModalClient(false)
        },
        // para actualizar o guardar un cliente
        onSubmit() {
            
            let vm = this

            const data = {
                id: vm.form.id,
                type: vm.form.type,
                name: vm.form.name,
                surname: vm.form.surname,
                lastname: vm.form.lastname,
                rfc: vm.form.rfc,
                curp: vm.form.curp,
                trademark: vm.form.trademark,
                email: vm.form.email,
                employerRegister: vm.form.employerRegister,
                password: vm.generatePassword()
            }
            // Actualiza lso datos del cliente
            if (vm.item) {
                
                vm.sendRequest('patch', 'client', data)

            } else {  // registra un nuevo cliente

                vm.sendRequest('post', 'client', data)

            }
            
        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'client/toggle-status',
                {
                    id: this.form.id
                }
            )

        }
    },
}
</script>