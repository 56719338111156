<template>
    
    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Usuarios
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingUsers"
                        :data="users"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">

                            <b-tooltip
                                label="Registrar nuevo usuario"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewUserModal"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingUsers" :can-cancel="false"></b-loading>
                    </b-notification>

                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalUser">
                        
                        <FormUser :item="selected" />
                        
                    </b-modal>
                </section>

                <!-- modal para registrar este módulo a un cliente-->
                <section>

                    <b-modal :active.sync="modalFormUser" :width="640" scroll="keep" :can-cancel="cancelModalUser">
                        
                        <FormNewUser />
                    </b-modal>
                </section>
            </div>
        </div>
    </div>
</template>

<script>

import FormUser from '@/components/user/FormUser.vue'
import FormNewUser from '@/components/user/FormNewUser.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            profileTypes: [1,2],
            showDetails: false,
            selected: null,
            currentPage: 1,
            perPage: 5,
            columns:[
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'Person.name',
                    label: 'Username',
                    searchable: true,
                    sortable: true
                },
                {
                    field: 'Person.email',
                    label: 'Email',
                    searchable: true,
                    sortable: true
                },
            ]
        }
    },
    components: {
        
        FormUser,
        FormNewUser
    },
    computed: {

        ...mapState('user', ['users', 'loadingUsers', 'modalFormUser', 'cancelModalUser'])
    },
    beforeMount() {
        
        let vm = this

        vm.getUsers(
            {
                profiles: vm.profileTypes,
                vm
            }
        )
    },
    watch: {

        selected: function(value) {

            if (value !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {
            if (value === false) {
                this.selected = null
            }
        }
    },
    methods: {
        
        ...mapActions('user', ['getUsers']),
        ...mapMutations('user', ['changeNewModalUser']),
        showNewUserModal() {

            this.changeNewModalUser(true)
        }
    },
}
</script>