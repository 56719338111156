<template>

    <div class="container">
        
        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Unidades de Negocio
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-field grouped group-multiline>

                        <b-tooltip
                            label="Nueva Unidad de Negocio"
                            position="is-bottom"
                            type="is-white">

                            <b-button
                                @click="showNewBranchOfficeForm"
                                class="add-button"
                                size="is-medium"
                                icon-left="plus-circle">
                            </b-button>
                        </b-tooltip>
                    </b-field>

                    <br>

                    <b-table
                        v-if="!loadingBranchOffices"
                        :data="branchOffices"
                        :columns="columns"
                        :selected.sync="selected"
                        hoverable
                        striped>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingBranchOffcies" :can-cancel="false"></b-loading>
                    </b-notification>

                </section>

                <!-- modal para registrar una nueva unidad de negocio-->
                <section>

                    <b-modal :active.sync="modalFormBranchOffice" :width="640" scroll="keep" :can-cancel="cancelModalBranchOffice">
                        
                        <FormNewBranchOffice />
                    </b-modal>
                </section>

                <!-- modal para mostrar datos de la unidad de negocio -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalBranchOffice">
                        
                        <FormBranchOffice :item="selected"/>
                    </b-modal>
                </section>
                
            </div>
        </div>
    </div>

</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormNewBranchOffice from '@/components/branchOffice/FormNewBranchOffice.vue'
import FormBranchOffice from '@/components/branchOffice/FormBranchOffice.vue'
export default {
    data() {
        return {
            selected: null,
            showDetails: false,
            currentPage: 1,
            perPage: 5,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'name',
                    label: 'Nombre',
                    searchable: true
                },
                {
                    field: 'description',
                    label: 'Descripción',
                    searchable: true
                }
            ]
        }
    },
    components: {

        FormNewBranchOffice,
        FormBranchOffice
    },
    computed: {
        ...mapState('branchOffice', ['loadingBranchOffices', 'branchOffices', 'modalFormBranchOffice', 'cancelModalBranchOffice'])
    },
    beforeMount() {

        this.getBranchOffices({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })
    },
    watch: {

        selected: function(data) {

            if (data !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {
            
                this.selected = null
            }
        }
    },
    methods: {
        ...mapActions('branchOffice', ['getBranchOffices']),
        ...mapMutations('branchOffice', ['changeNewModalBranchOffice']),
        showNewBranchOfficeForm() {
            this.changeNewModalBranchOffice(true)
        }
    }
}
</script>