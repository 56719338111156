<template>
    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Prestaciones
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>
                    <b-field grouped group-multiline>

                        <b-tooltip
                            v-if="benefits.length < 1"
                            label="Nuevas Prestaciones"
                            position="is-bottom"
                            type="is-white">

                            <b-button
                                @click="showNewBenefitForm"
                                class="add-button"
                                size="is-medium"
                                icon-left="plus-circle">
                            </b-button>
                        </b-tooltip>
                    </b-field>

                    <br>

                    <b-table
                        v-if="!loadingBenefits"
                        :data="benefits"
                        :columns="columns"
                        :selected.sync="selected"
                        hoverable
                        striped>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingBenefits" :can-cancel="false"></b-loading>
                    </b-notification>

                </section>


                <!-- modal para registrar este módulo a un calendario-->
                <section>

                    <b-modal :active.sync="modalFormBenefit" :width="640" scroll="keep" :can-cancel="cancelModalBenefit">
                        
                        <FormNewBenefit />
                    </b-modal>
                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalBenefit">
                        
                        <FormBenefit :item="selected"/>
                    </b-modal>
                </section>

            </div>

        </div>

    </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FormNewBenefit from '@/components/benefit/FormNewBenefit.vue'
import FormBenefit from '@/components/benefit/FormBenefit.vue'
export default {
    data() {
        return {
            showDetails: false,
            selected: null,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'authorizers',
                    label: 'No. autorizantes'
                }
            ]
        }
    },
    components: {
        FormNewBenefit,
        FormBenefit
    },
    computed: {
        
        ...mapState('benefit', ['benefits', 'loadingBenefits', 'cancelModalBenefit', 'modalFormBenefit'])
    },
    beforeMount() {
        
        this.getBenefits({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })
    },
    watch: {
        selected: function(value) {

            if (value !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {

                this.selected = null
            }
        }
    },
    methods: {
        
        ...mapMutations('benefit', ['loadBenefits', 'changeNewModalBenefit']),
        ...mapActions('benefit', ['getBenefits']),
        showNewBenefitForm() {
            this.changeNewModalBenefit(true)
        }
    }
}
</script>