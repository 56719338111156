<template>
    
    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Calendarios
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingCalendars"
                        :data="calendars"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left" >
                            <b-tooltip
                                label="Registrar módulo a cliente"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showAddToClientForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                        <b-table-column field="id" label="ID" width="50" v-slot="props">
                            {{ props.row.id}}
                        </b-table-column>

                        <b-table-column field="year" label="Año" v-slot="props">
                            {{ props.row.year.slice(0,4)}}
                        </b-table-column>

                    </b-table>


                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingCalendars" :can-cancel="false"></b-loading>
                    </b-notification>
                </section>

                <!-- modal para mostrar datos del calendario -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalCalendar">
                        
                        <FormCalendar :item="selected"/>
                    </b-modal>
                </section>

                <!-- modal para registrar este módulo a un calendario-->
                <section>

                    <b-modal :active.sync="modalFormCalendar" :width="640" scroll="keep" :can-cancel="cancelModalCalendar">
                        
                        <FormNewCalendar />
                    </b-modal>
                </section>
            </div>
        </div>
        
    </div>
</template>

<script>
import FormCalendar from '@/components/calendar/FormCalendar.vue'
import FormNewCalendar from '@/components/calendar/FormNewCalendar.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            showDetails: false,
            selected: null,
            perPage: 5,
            currentPage: 1,
        }
    },
    components: {

        FormCalendar,
        FormNewCalendar,

    },
    computed: {
        
        ...mapState('calendar', ['calendars', 'loadingCalendars', 'cancelModalCalendar', 'modalFormCalendar']),
        ...mapState('holiday', ['holidays'])
    },
    beforeMount() {
        
        this.getCalendars({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })
    },
    watch: {
        selected: function(value) {

            if (value !== null) {
                
                this.getHolidays({
                    calendarId: value.id,
                    vm: this
                })
            }
        },
        holidays: function(data) {
            if (data !== null) {
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {
            
                this.selected = null
            }
        }
    },
    methods: {
        
        ...mapMutations('calendar', ['loadCalendars', 'changeNewModalCalendar']),
        ...mapActions('calendar', ['getCalendars']),
        ...mapActions('holiday', ['getHolidays']),
        showAddToClientForm() {
            this.changeNewModalCalendar(true)
        }
    },


}
</script>