<template>

        <div class="card">

            <div class="card-header header-fhc">
                <div class="card-header-title pading-left text-white">
                    <p>Nuevo Calendario</p>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>

                    <form @submit.prevent="onSubmit">

                        <b-field label="Año">
                            <b-input
                                v-model="form.year"
                                required
                                type="number"
                                :min="minYear"
                                :max="maxYear"
                                ></b-input>
                        </b-field>

                        <br>

                        <div class="buttons">

                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Guardar"
                                :loading="loading"
                                expanded/>

                            <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                        </div>
                    </form>
                </section>

            </div>
        </div>
    
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            minYear: null,
            maxYear: null,
            form: {
                year: null
            }
        }
    },
    mixins: [
        mixins
    ],
    beforeMount() {
         
        let vm = this

        let d = new Date()
        vm.minYear = d.getFullYear()
        vm.maxYear = parseInt(d.getFullYear()) + 10
        
        vm.changeCancelModalCalendar(false)
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa un calendario nuevo
            if (data.calendar) {

                vm.addCalendar(data.calendar)
                vm.cancel()
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {
        
        ...mapMutations('calendar', ['changeCancelModalCalendar', 'changeNewModalCalendar', 'addCalendar']),
        cancel() {

            this.changeCancelModalCalendar(true)
            this.changeNewModalCalendar(false)
        },
        onSubmit() {

            let vm = this

            const data = {
                clientId: sessionStorage.getItem('clientId'),
                year: vm.form.year + '-01-01'
            }

            vm.sendRequest('post', 'calendar', data)

        }

    },
}
</script>