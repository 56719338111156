<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title">

                <div class="card-header-title pading-left text-white">

                    Datos de las prestaciones
                </div>
            </div>
            
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <b-tabs v-else position="is-centered" class="block" :animated="true">

                    <b-tab-item label="Configuración" icon="cog">

                        <form @submit.prevent="onSubmit">
                                    
                            <b-field label="Días de vigencia">
                                <b-input v-model="form.validity" type="number" min="1" max="365" :readonly="readonly" required></b-input>
                            </b-field>

                            <b-field label="Dias consecutivos máximos">
                                <b-input v-model="form.consecutiveDays" type="number" :readonly="readonly" required></b-input>
                            </b-field>

                            <b-field label="Prima vacacional (%)">
                                <b-input v-model="form.holidayBonus" type="number" min="1" :readonly="readonly" required></b-input>
                            </b-field>

                            <b-field label="Aguinaldo (días)">
                                <b-input v-model="form.bonus" type="number" min="1" :readonly="readonly" required></b-input>
                            </b-field>

                            <b-field label="Número de autorizantes">
                                <b-input v-model="form.authorizers" type="number" min="1" :readonly="readonly" required></b-input>
                            </b-field>

                            <div v-if="readonly" class="buttons">
                        
                                <b-button
                                    type="is-primary"
                                    @click="toUpdate"
                                    expanded>

                                    Actualizar
                                </b-button>
                            </div>
                            
                            <div v-else class="buttons">
                                
                                <b-button
                                    :tag="tag"
                                    native-type="submit"
                                    type="is-primary"
                                    value="Aceptar"
                                    expanded/>

                                
                                <b-button
                                    @click="cancel"
                                    type="is-success"
                                    expanded>

                                    Cancelar
                                </b-button>
                            </div>

                        </form>
                    </b-tab-item>

                    <b-tab-item label="Días por año" icon="calendar-day">

                        <VacationDay :item="item" />
                    </b-tab-item>

                </b-tabs>

            </section>

        </div>

    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import VacationDay from '@/components/vacationDay/VacationDay.vue'
import mixins from '../../mixins/mixins'
export default {
    data() {
        return {
            readonly: true,
            form: {
                id: null,
                validity: null,
                consecutiveDays: null,
                holidayBonus: null,
                bonus: null,
                authorizers: null
            }
        }
    },
    mixins: [
        mixins
    ],
    components: {

        VacationDay
    },
    props: [
        'item'
    ],
    beforeMount() {

        let vm = this
        
        if (vm.item) {

            vm.loadDataFromItem()
        }
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura actualizada
            if (data.updated) {

                vm.updateBenefit(data.updated)
                
                vm.readonly = true
                    
            }

            vm.changeCancelModalBenefit(true)
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {
        ...mapMutations('benefit', ['updateBenefit', 'changeCancelModalBenefit']),
        loadDataFromItem() {

            let vm = this

            vm.form.id = vm.item.id
            vm.form.validity = vm.item.validity
            vm.form.consecutiveDays = vm.item.consecutiveDays
            vm.form.holidayBonus = vm.item.holidayBonus
            vm.form.bonus = vm.item.bonus
            vm.form.authorizers = vm.item.authorizers
        },
        toUpdate() {
            this.readonly = false
        },
        cancel() {
            this.readonly = true
        },
        onSubmit() {
            
            let vm = this

            const data = {
                id: vm.form.id,
                validity: vm.form.validity,
                consecutiveDays: vm.form.consecutiveDays,
                holidayBonus: vm.form.holidayBonus,
                bonus: vm.form.bonus,
                authorizers: vm.form.authorizers,
            }

            vm.sendRequest('patch', 'benefit', data)
        }
    }
}
</script>