<template>

    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Estructuras
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingStructures"
                        :data="structures"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">

                            <b-tooltip
                                label="Registrar modulo a cliente"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showAddToClientForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingStructures" :can-cancel="false"></b-loading>
                    </b-notification>

                </section>

                <!-- modal para mostrar datos del módulo -->
                <section>

                    <b-modal :active.sync="showDetails" :width="640" scroll="keep" :can-cancel="cancelModalStructure">
                        
                        <FormStructure :item="selected"/>
                    </b-modal>
                </section>
                
                <!-- modal para registrar este módulo a un cliente-->
                <section>

                    <b-modal :active.sync="modalFormStructure" :width="640" scroll="keep" :can-cancel="cancelModalStructure">
                        
                        <FormNewStructure />
                    </b-modal>
                </section>
            </div>
        </div>

    </div>
</template>

<script>

import FormStructure from '@/components/structure/FormStructure.vue'
import FormNewStructure from '@/components/structure/FormNewStructure.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
    data() {
        return {
            showDetails: false,
            selected: null,
            currentPage: 1,
            perPage: 5,
            profile: [1,2],
            columns:[
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'name',
                    label: 'Nombre',
                    searchable: true
                },
                {
                    field: 'description',
                    label: 'Descripción',
                    searchable: true
                }
            ]
        }
    },
    components: {
        FormStructure,
        FormNewStructure
    },
    computed: {

        ...mapState('structure', ['structures', 'loadingStructures', 'cancelModalStructure', 'modalFormStructure'])
    },
    beforeMount() {
        
        this.getStructures({
            clientId: sessionStorage.getItem('clientId'),
            vm: this
        })
    },
    watch: {

        selected: function(value) {

            if (value !== null) {
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {

                this.selected = null
            }
        },

    },
    methods: {

        ...mapActions('structure', ['getStructures']),
        ...mapMutations('structure', ['changeNewModalStructure']),
        showAddToClientForm() {

             this.changeNewModalStructure(true)
        }
    },
}
</script>