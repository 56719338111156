const mixin = {

    methods: {
    
        generatePassword() {
            
            var minusculas = "abcdefghijkmnpqrtuvwxyz"
            var mayusculas = "ABCDEFGHIJKLMNPQRTUVWXYZ"
            var numeros = "12346789"
            var especiales = "!@#%&*()_.;:{}=+"
            var contrasena = "";

            contrasena += mayusculas.charAt(Math.floor(Math.random()*mayusculas.length))
            contrasena += mayusculas.charAt(Math.floor(Math.random()*mayusculas.length))
            contrasena += numeros.charAt(Math.floor(Math.random()*numeros.length))
            contrasena += numeros.charAt(Math.floor(Math.random()*numeros.length))
            contrasena += especiales.charAt(Math.floor(Math.random()*especiales.length))
            contrasena += especiales.charAt(Math.floor(Math.random()*especiales.length))
            contrasena += minusculas.charAt(Math.floor(Math.random()*minusculas.length))
            contrasena += minusculas.charAt(Math.floor(Math.random()*minusculas.length))


            const arrayPass = Array.from(contrasena)
            const suffle = arrayPass.sort(function() {
                return Math.random() - 0.5
            })
            const joined = suffle.toString().replace(/,/g, '')
            
            return joined
        }

    }
}

export default (

    mixin
)