<template>

    <div>
        <div v-if="!showDetails && !showForm">

            <section>

                <b-table
                    v-if="item"
                    :data="item.VacationDays"
                    hoverable
                    striped
                    paginated
                    :selected.sync="selected"
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                    pagination-position="top">

                    <template slot="top-left">

                        <b-tooltip
                            label="Registrar un nuevo día festivo"
                            position="is-bottom"
                            type="is-white">

                            <b-button
                                @click="showNewVacationDayForm"
                                class="add-button"
                                size="is-medium"
                                icon-left="plus-circle">
                            </b-button>
                        </b-tooltip>
                    </template>

                    <template slot="empty">

                        <section class="section">
                            <div class="content has-text-grey has-text-centered">
                                <p>
                                    <b-icon
                                        icon="frown"
                                        size="is-large">
                                    </b-icon>
                                </p>
                                <p>No existen coincidencias.</p>
                            </div>
                        </section>

                    </template>

                    <b-table-column field="initialYear" label="Periodo (año)" width="40" numeric v-slot="props">
                        {{ props.row.initialYear}}
                    </b-table-column>

                    <b-table-column field="finalYear" label="A (años)" numeric v-slot="props">
                        {{ props.row.finalYear}}
                    </b-table-column>

                    <b-table-column field="days" label="Días" numeric v-slot="props">
                        {{ props.row.days}}
                    </b-table-column>

                </b-table>
            </section>
        </div>

        <!-- modal para registrar este módulo a un cliente-->
        <div v-else-if="showForm">

            <section>

                <FormNewVacationDay
                    :benefitId="item.id"
                    @addData="addDayToBenefit"
                    @hideForm="hideForm"    
                />
            </section>
        </div>

        <!-- modal para mostrar datos del módulo -->
        <div v-else-if="showDetails">
            <section>

                <FormVacationDay
                    :item="selected"
                    @updateData="updateDay"
                    @hideForm="showForm = $event"/>
            </section>
        </div>

    </div>

</template>

<script>

import FormNewVacationDay from '@/components/vacationDay/FormNewVacationDay.vue'
import FormVacationDay from '@/components/vacationDay/FormVacationDay.vue'
import { mapMutations } from 'vuex'

export default {
    data() {
        return {
            showDetails: false,
            showForm: false,
            selected: null,
            currentPage: 1,
            perPage: 5
        }
    },
    props:[
        'item'
    ],
    components: {
        
        FormNewVacationDay,
        FormVacationDay
    },
    watch: {
        selected: function(data) {
            
            if (data !== null) {
                
                this.changeCancelModalBenefit(false)
                this.showDetails = true
            }
        }
    },
    methods: {
        
        ...mapMutations('benefit', ['changeCancelModalBenefit', 'addVacationDay', 'updateVacationDay']),
        showNewVacationDayForm() {

            this.changeCancelModalBenefit(false)
            this.showForm = true
        },
        addDayToBenefit(data) {
            
            this.addVacationDay(data)
            this.showForm = false
            this.changeCancelModalBenefit(true)
        },
        updateDay(data) {

            this.updateVacationDay(data),
            this.showDetails = false
            this.changeCancelModalBenefit(true)
            this.selected = null
        },
        hideForm(type) {

            if (type == 'update') {
                
                this.selected = null
            } else if (type == 'insert') {
                
                this.showForm = false
            }
            this.changeCancelModalBenefit(true)
        }
    },
}
</script>