<template>
    
    <div class="card">

        <div class="card-header header-fhc">
            <div class="card-header-title pading-left text-white">
                <p>Nuevas prestaciones</p>
            </div>
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Días de vigencia">
                        <b-input v-model="form.validity" type="number" min="1" max="365" required></b-input>
                    </b-field>

                    <b-field label="Dias consecutivos máximos">
                        <b-input v-model="form.consecutiveDays" type="number" required></b-input>
                    </b-field>

                    <b-field label="Prima vacacional (%)">
                        <b-input v-model="form.holidayBonus" type="number" min="1" required></b-input>
                    </b-field>

                    <b-field label="Aguinaldo (días)">
                        <b-input v-model="form.bonus" type="number" min="1" required></b-input>
                    </b-field>

                    <b-field label="Número de autorizantes">
                        <b-input v-model="form.authorizers" type="number" min="1" max="2" required></b-input>
                    </b-field>

                    <br>

                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                    </div>
                </form>
            </section>

        </div>
    </div>

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import mixins from '../../mixins/mixins'
export default {
    data() {
        return {
            form: {
                validity: null,
                consecutiveDays: null,
                holidayBonus: null,
                bonus: null,
                authorizers: null
            }
        }
    },
    mixins: [
        mixins
    ],
    computed: {

        ...mapState('benefit', ['benefits']),
    },
    beforeMount() {
         
        this.changeCancelModalBenefit(false)
    },
    watch: {

        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // agrega las prestaciones a los datos
            if (data.benefit) {

                vm.addBenefit(data.benefit)
                vm.changeNewModalBenefit(false)
                
            }
        },
        errors(data) {
        
            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        }
    },
    methods: {
        
        ...mapMutations('benefit', ['changeCancelModalBenefit', 'changeNewModalBenefit', 'addBenefit']),
        cancel() {

            this.changeCancelModalBenefit(true)
            this.changeNewModalBenefit(false)
        },
        onSubmit() {

            let vm = this

            const data = {
                clientId: sessionStorage.getItem('clientId'),
                validity: vm.form.validity,
                consecutiveDays: vm.form.consecutiveDays,
                holidayBonus: vm.form.holidayBonus,
                bonus: vm.form.bonus,
                authorizers: vm.form.authorizers
            }

            vm.sendRequest('post', 'benefit', data)

        }
    }
}
</script>