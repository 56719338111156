<template>
    
    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">
                <p>Nuevo Colaborador</p>
            </div>
        </div>
        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field label="Fecha de alta">
                        <b-datepicker
                            placeholder="Click para seleccionar..."
                            v-model="form.initial_date"
                            :date-formatter="dateFormatter"      
                        >
                        </b-datepicker>
                    </b-field>

                    <b-field label="Unidad de Negocio">
                        <b-select v-model="form.branchOfficeId" required expanded>
                            
                            <option
                                v-for="option in getAvaliableBranchOffices('Activo')"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Tipo de colaborador">
                        <b-select v-model="form.collaboratorTypeId" required expanded>
                            
                            <option
                                v-for="option in getAvaliableCollaboratorTypes('Activo')"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field v-if="authorizers > 1" label="¿Autoriza vacaciones de subordinados?">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="form.authorizing">

                        </b-switch>
                    </b-field>

                    <b-field label="Departamento">
                        <b-select v-model="departmentId" required expanded>
                            <option
                                v-for="option in getAvaliableDepartments('Activo')"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Puesto">
                        <b-select v-model="form.jobId" required expanded>
                            <option
                                v-for="option in getAvaliableJobs('Activo')"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Tiene Jefe o Autorizador">
                        <b-switch
                            :rounded="false"
                            type="is-primary"
                            v-model="hasBoss">

                        </b-switch>
                    </b-field>

                    <b-notification
                        v-if="getBosses.length < 1"
                        type="is-warning"
                        :closable="closable"
                        role="alert">
                        No existen registrados jefes, recuerda primero registrar alos jefes
                    </b-notification>

                    <b-field v-if="hasBoss" label="Jefe o Autorizador">
                        <b-select v-model="form.bossId" required expanded>
                            <option
                                v-for="option in getBosses(0)"
                                :value="option.id"
                                :key="option.id">
                                {{ option.name}}
                            </option>
                        </b-select>
                    </b-field>

                    <b-field label="Email">
                        <b-input v-model="form.email" type="email" required></b-input>
                    </b-field>

                    <b-field label="Contraseña" message="Guarda este dato en un lugar seguro" type="is-danger">
                        <b-field >
                            <b-input placeholder="Contraseña"
                            :value="form.password"
                            readonly
                            expanded>
                            
                        </b-input>
                        <p class="control">
                            <b-button @click="generate" class="is-primary">Generar</b-button>
                        </p>
                        </b-field>
                    </b-field>

                    <b-field label="Nombre">
                        <b-input v-model="form.name" required></b-input>
                    </b-field>

                    <b-field label="Apellido Paterno">
                        <b-input v-model="form.surname" required></b-input>
                    </b-field>

                    <b-field label="Apellido Materno">
                        <b-input v-model="form.lastname" required></b-input>
                    </b-field>

                    <br>

                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                    </div>
                </form>
            </section>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'
import generatePassword from '../../mixins/generatePassword'

export default {
    data() {
        return {
            closable: false,
            departmentId: null,
            hasBoss: false,
            form: {
                initial_date: null,
                authorizing: false,
                bossId: 0,
                email: null,
                name: null,
                surname: null, 
                lastname: null,
                branchOfficeId: null,
                jobId: null,
                collaboratorTypeId: null,
                password: null
            }
        }
    },
    props: [
        'authorizers'
    ],
    mixins: [
        mixins,
        dateFormatter,
        generatePassword
    ],
    computed: {
        
        ...mapGetters('branchOffice', ['getAvaliableBranchOffices']),
        ...mapGetters('collaboratorType', ['getAvaliableCollaboratorTypes']),
        ...mapGetters('department', ['getAvaliableDepartments']),
        ...mapGetters('job', ['getAvaliableJobs']),
        ...mapGetters('collaborator', ['getBosses']),
    },
    beforeMount() {
        
        this.changeCancelModalCollaborator(false)
    },
    watch: {
        hasBoss(value) {

            if (value == true) {
                this.form.bossId = null
            }
            else {
                this.form.bossId = 0
            }
        },
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura nueva
            if (data.collaborator) {

                vm.addCollaborator(data.collaborator)
                vm.cancel()
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        departmentId: function(value) {

            if (value != null) {
                this.getJobs(
                    {
                        departmentId: value,
                        vm: this
                    }
                )
            }
        },
    },
    methods: {
        
        ...mapMutations('collaborator', ['changeCancelModalCollaborator', 'changeNewModalCollaborator', 'addCollaborator']),
        ...mapActions('job', ['getJobs']),
        cancel() {
            this.changeCancelModalCollaborator(true)
            this.changeNewModalCollaborator(false)
        },
        onSubmit() {

            let vm = this

            vm.loading = true
            vm.tag = 'button'
            const data = {
                initial_date: vm.dtf.format(vm.form.initial_date),
                authorizing: vm.form.authorizing,
                bossId: vm.form.bossId,
                email: vm.form.email,
                name: vm.form.name,
                lastname: vm.form.lastname,
                surname: vm.form.surname,
                jobId: vm.form.jobId,
                collaboratorTypeId: vm.form.collaboratorTypeId,
                branchOfficeId: vm.form.branchOfficeId,
                password: vm.form.password,
                structureId: sessionStorage.getItem('structureId')
            }

            vm.sendRequest('post', 'collaborator', data)

        },
        generate() {
            this.form.password = this.generatePassword()
        }
    },
}
</script>