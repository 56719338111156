<template>
    
    <div class="card">

        <div
            class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">

                <p>Datos de las vacaciones del año {{item.aniversary.slice(0,4)}}</p>
            </div>
            
        </div>

        <div  class="card-content">

            <section v-if="datepickerVisible">

                <b-notification
                    type="is-info"
                    has-icon
                    :closable="false"
                >
                    Tienes {{item.days - (datesSelected.length + item.used_days) }} días disponibles
                    <b-progress
                        type="is-success"
                        :value="(datesSelected.length + item.used_days)"
                        show-value
                        :max="item.days"
                        size="is-large">
                    </b-progress>

                </b-notification>
                
                <b-datepicker
                    v-if="item.days - (datesSelected.length + item.used_days) > 0"
                    inline
                    v-model="datesSelected"
                    locale="es-MX"
                    :month-names="monthsName"
                    :day-names="daysName"
                    :focused-date="currentDate"
                    :min-date="min_date"
                    :max-date="max_date"
                    :events="events"
                    indicators="dots"
                    :unselectable-days-of-week="[0,6]"
                    :unselectable-dates="holidays"
                    multiple>
                </b-datepicker>

                <br>

                <div
                    v-if="datesSelected.length > 0"
                    class="buttons"
                >
                    <b-button
                        type="is-primary"
                        @click="send"
                        :tag="tag"
                        native-type="submit"
                        value="Solicitar"
                        :loading="loading"
                        >
                    </b-button>
                    <b-button type="is-danger" @click="clearDates" :disabled="buttonsDisbaled">Borrar Todo</b-button>
                </div>

            </section>

            <section v-else>

                <b-notification
                    v-if="!datepickerVisible"
                    type="is-info"
                    has-icon
                    :closable="false"
                >
                    Tienes los siguinetes días solicitados que estan en proceso de autorización,
                    una vez que hayan sido confirmadas podras solicitar más si aún tienes días disponibles
                </b-notification>

            </section>

            <section v-if="item.VacationDates">
                <br>
                <div class="card">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3">
                        <p class="card-header-title">
                            Fechas Solicitadas Previamente
                        </p>
                    </div>
                    <div class="card-content">
                        <div class="content">
                            <b-table
                                :data="item.VacationDates"
                                >

                                <b-table-column field="date" label="Fecha" sortable numeric v-slot="props">
                                    {{ props.row.date}}
                                </b-table-column>

                                <b-table-column field="status" label="Status" sortable numeric v-slot="props">
                                    {{ props.row.status}}
                                </b-table-column>
                                
                            </b-table>
                        </div>
                    </div>
                </div>
            </section>
            
        </div>
    </div>
</template>

<script>
import mixins from '../../mixins/mixins'
import dateFormatter from '../../mixins/dateFormatter'
import { mapMutations, mapState } from 'vuex'
export default {
    data() {
        return {
            datepickerVisible: true,
            datesSelected: [],
            currentDate: null,
            min_date: null,
            max_date: null,
            holidays: [],
            lastLength: null,
            buttonsDisbaled: false, // deshabilita los botones diferentes a solicitar
            events: []
        }
    },
    props: [
        'item',
        'consecutiveDays'
    ],
    mixins: [
        mixins,
        dateFormatter
    ],
    computed: {

        ...mapState('app', ['path', 'monthsName', 'daysName']),
        ...mapState('holiday', ['allHolidays'])
    },
    beforeMount() {
        
        let vm = this
        
        // se establecen los límites del calendario
        vm.min_date = new Date()
        vm.max_date = new Date(vm.item.validity)
        // cargamos los días feriados del Cliente
        vm.allHolidays.forEach(element => {

            vm.holidays.push(vm.toDateObject(element.date))
        })
        
        if (vm.item.VacationDates.length > 0) {
            
            vm.item.VacationDates.forEach(element => {
                
                if (element.status !== 'Autorizada') {
                    vm.datepickerVisible = false
                }
                // deshabilita las fechas ya solicitadas por el colaborador
                vm.holidays.push(
                    new Date(element.date.substring(0,4), element.date.substring(5,7) - 1, element.date.substring(8,10))
                )
                vm.events.push(
                    new Date(element.date.substring(0,4), element.date.substring(5,7) - 1, element.date.substring(8,10))
                )
                
            })
        }
        
    },
    watch: {
        
        datesSelected: function(data) {
 
            let vm = this
            
            if (this.lastLength !== data.length) {

                if (data.length > 0) {
                    
                    // actualiza la fecha del calendario a la última solicitada
                    vm.currentDate = new Date(data[data.length -1])

                    data = data.sort((a, b) => { return a.getTime() - b.getTime()})
            
                    vm.validateContinues(data)

                } else {
                    vm.currentDate = new Date()
                }

                this.lastLength = data.length
            }
            
        },
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa un calendario nuevo
            if (data.vacationDates) {
                
                vm.addVacationDates({
                    id: vm.item.id,
                    vacationDates: data.vacationDates
                })

                vm.datepickerVisible = false

                vm.buttonsDisbaled = false
                vm.changeCollaboratorVacationCancelModal(true)
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {

        ...mapMutations('collaboratorVacation', ['changeCollaboratorVacationCancelModal']),
        ...mapMutations('collaborator', ['addVacationDates']),
        // valida el máximo de días consecutivos permitidos para seleccionar
        validateContinues(data) {

            let continues = 0;
            
            for (let index = 0; index < data.length; index++) {
                if ( data[index + 1]) {
    
                    // diferencia de días entre dos fechas seleccionadas
                    const difference = Math.round((data[index + 1].getTime() - data[index].getTime())/(1000*60*60*24))
    
                    if (difference == 1 ) {
                        continues ++
                    } else if (difference == 2) {
                        var aux = this.validate(data[index],1)
                        aux > 0 ? continues ++: continues = 0
                    } else if(difference == 3) {
                        if (data[index].getDay() == 5 && data[index + 1].getDay() == 1) {
                            continues ++
                        } else {
                            continues = 0
                        }
                    } else if (difference == 4) {
                        if (data[index].getDay() == 5 && this.validate(data[index],3) > 0) {
                            continues ++
                        } else if (data[index + 1].getDay() == 1 && this.validate(data[index],3) > 1) {
                            continues ++
                        } else {
                            continues = 0
                        }
                    } else if (difference == 5) {
                        var aux1 = this.validate(data[index],1)
                        var aux2 = this.validate(data[index],2)
                        aux1 > 0 && aux2 > 0 ? continues ++: continues = 0
                    } else {
                        continues = 0
                    }
                    
                    if (continues >= this.consecutiveDays) {

                        this.datesSelected.splice(index + 1,1)

                        this.$buefy.toast.open({
                            duration: 5000,
                            message: `Solo puedes elegir un máximo de ${this.consecutiveDays} días consecutivos`,
                            type: 'is-danger'
                        })

                    }
                }
            }
            
        },
        // valida si el día es un día festivo para el cliente para contarlo como continuo
        validate(date, days) {

            let aux = 0
            let dateTmp = new Date(date.getFullYear(), date.getMonth(), date.getDate())
            dateTmp.setDate(dateTmp.getDate() + days)
            this.holidays.forEach(
                element => {
                    if (element.getDate() === dateTmp.getDate()
                        && element.getMonth() === dateTmp.getMonth()
                        && element.getFullYear() === dateTmp.getFullYear()
                    ) {
                        aux ++
                    }
                }
            )

            return aux
        },
        // borra todas las fechas seleccionadas y restaura con las fechas previas
        clearDates() {

            let vm = this
            vm.datesSelected.splice(0, vm.datesSelected.length)
            

        },
        
        send() {
            
            let vm = this

            vm.buttonsDisbaled = true
            vm.changeCollaboratorVacationCancelModal(false)


            // convertimos en fechas legibles para la base de datos las fechas seleccionadas
            let dates = Array()
            vm.datesSelected.forEach(
                element => {
                    dates.push(vm.dateFormatter(element))
                }
            )
            
            const data = {
                dates,
                vacationId: vm.item.id
            }

            vm.sendRequest('post', 'vacation-date', data)

        }
    },
}
</script>