<template>

    <div>

        <transition name="slide-fade" mode="out-in">

            <div v-if="!showDetails && !showForm">

                <section>

                    <b-table
                        v-if="!loadingJobs"
                        :data="jobs"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">
                            <b-tooltip
                                label="Nuevo trabajo"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewJobForm"
                                    class="add-button-in-card"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingJobs" :can-cancel="false"></b-loading>
                    </b-notification>
                </section>
            </div>

            <div v-else-if="showDetails">

                <section>

                    <FormJob @hideDetails="hideDetails" :item="selected" />
                </section>
            </div>

            <div v-else-if="showForm">

                <section>
                    <FormNewJob
                        :departmentId="departmentId"
                        @hideForm="showForm = $event"
                        @newJobAdded="newJobAdded"
                    />
                </section>
            </div>

        </transition>
    </div>
    
</template>

<script>
import { mapActions, mapState } from 'vuex'
import FormJob from '@/components/job/FormJob.vue'
import FormNewJob from '@/components/job/FormNewJob.vue'

export default {
    data() {
        return {
            showDetails: false,
            showForm: false,
            showTable: true,
            currentPage: 1,
            perPage: 5,
            selected: null,
            columns: [
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'name',
                    label: 'Nombre',
                    searchable: true
                }
            ]
        }
    },
    props: [
        'departmentId'
    ],
    components: {
        FormJob,
        FormNewJob
    },
    computed: {

        ...mapState('job', ['jobs', 'loadingJobs'])
    },
    beforeMount() {
        
        let vm = this

        vm.getJobs(
            {
                departmentId: vm.departmentId,
                vm
            }
        )
    },
    watch: {
        jobs: function() {

            if (this.selected == null) {
                
                this.showDetails = false
                this.showForm = false
            }
            
        },
        selected: function(data) {

            if (data != null) {
                this.showDetails = true
            }
        }
    },
    methods: {
        
        ...mapActions('job', ['getJobs']),
        showNewJobForm() {
            this.showForm = true
        },
        hideDetails() {

            let vm = this
            vm.showDetails = false
            vm.selected = null
        },
        newJobAdded() {

            this.getJobs(
                {
                    departmentId: this.departmentId,
                    vm: this
                }
            )
        }
    },
}
</script>