<template>

    <div class="container">

        <div class="card card-transparent">

            <div class="card-header">

                <div class="card-header-title">
                    
                    <div class="columns">
                        
                        <div class="column is-offset-2  text-white text-card-title">
                            Clientes
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-content">

                <section>

                    <b-table
                        v-if="!loadingClients"
                        :data="clients"
                        :columns="columns"
                        hoverable
                        striped
                        paginated
                        :selected.sync="selected"
                        :per-page="perPage"
                        :current-page.sync="currentPage"
                        pagination-position="top">

                        <template slot="top-left">
                            <b-tooltip
                                label="Registrar nuevo cliente"
                                position="is-bottom"
                                type="is-white">

                                <b-button
                                    @click="showNewClientForm"
                                    class="add-button"
                                    size="is-medium"
                                    icon-left="plus-circle">
                                </b-button>
                            </b-tooltip>
                        </template>

                        <template slot="empty">

                            <section class="section">
                                <div class="content has-text-grey has-text-centered">
                                    <p>
                                        <b-icon
                                            icon="frown"
                                            size="is-large">
                                        </b-icon>
                                    </p>
                                    <p>No existen coincidencias.</p>
                                </div>
                            </section>

                        </template>

                    </b-table>

                    <b-notification v-else :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loadingClients" :can-cancel="false"></b-loading>
                    </b-notification>

                </section>

                <!-- modal para mostrar datos del cliente -->
                <section>

                    <b-modal 
                        :active.sync="showDetails" 
                        :width="640" 
                        scroll="keep" 
                        :can-cancel="cancelModalClient"
                    >
                        
                        <FormClient :item="selected" />
                    </b-modal>
                </section>

                <!-- modal para un nuevo cliente -->
                <section>

                    <b-modal
                        :active.sync="modalFormClient"
                        :width="640" 
                        scroll="keep"
                        :can-cancel="cancelModalClient"
                    >
                        
                        <FormClient />
                    </b-modal>
                </section>
            </div>
            
        </div>

    </div>

</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex'
import FormClient from '@/components/client/FormClient.vue'

export default {
    data() {
        return {
            showDetails: false,
            selected: null,
            curp:null,
            currentPage: 1,
            perPage: 5,
            columns:[
                {
                    field: 'id',
                    label: 'ID',
                    width: '40',
                    numeric: true
                },
                {
                    field: 'trademark',
                    label: 'Razón social',
                    searchable: true,
                },
                {
                    field: 'Person.name',
                    label: 'Nombre',
                    searchable: true,
                },
                {
                    field: 'Person.surname',
                    label: 'Apellido Paterno',
                    searchable: true,
                },
                {
                    field: 'Person.lastname',
                    label: 'Apellido Materno',
                },
                {
                    field: 'rfc',
                    label: 'RFC',
                    searchable: true,
                },
            ]
        }
    },
    components: {
        FormClient
    },
    computed: {

        ...mapState('client', 
            [
                'loadingClients',
                'clients',
                'cancelModalClient',
                'modalFormClient'
            ]
        )
    },
    beforeMount() {

        this.getClients(this)
        
    },
    watch: {

        selected: function(data) {

            if (data !== null) {
                
                this.showDetails = true
            }
        },
        showDetails: function(value) {

            if (value === false) {
            
                this.selected = null
            }
        }
    },
    methods: {

        ...mapActions('client', ['getClients']),
        ...mapMutations('client', ['loadClients', 'changeNewModalClient']),
        showNewClientForm() {
            
            this.changeNewModalClient(true)
        }
        
    },
}
</script>