<template>
    
    <section>

        <div class="card">
            
            <div class="card-header header-fhc">

                <div class="card-header-title pading-left text-white">
                    <p >Detalles de  {{form.name}}</p>

                </div>
                
            </div>

            <div class="card-content">

                <section>

                    <b-notification v-if="loading" :closable="false">
                        <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                    </b-notification>

                    <form @submit.prevent="onSubmit">

                        <b-field label="Nombre">
                            <b-input v-model="form.name" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Descripción">
                            <b-input v-model="form.description" :readonly="readonly" required></b-input>
                        </b-field>

                        <b-field label="Estado">
                            <b-switch
                                :rounded="false"
                                type="is-primary"
                                v-model="status">

                                {{form.status}}
                            </b-switch>
                        </b-field>

                        <br>

                        <div v-if="readonly" class="buttons">

                            <b-button type="is-primary" @click="toUpdate" expanded>Actualizar</b-button>
                            <b-button type="is-success" @click="closeDetails" expanded>regresar</b-button>
                        </div>

                        <div v-else class="buttons">

                            <b-button
                                :tag="tag"
                                native-type="submit"
                                type="is-primary"
                                value="Aceptar"
                                :loading="loading" 
                                expanded/>

                            <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>
                        </div>

                    </form>
                </section>

            </div>
        </div>
    </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            readonly: true,
            status: false,
            form: {
                id: null,
                name: null,
                description: null,
                status: null
            }
        }
    },
    mixins:[
        mixins
    ],
    props:[
        'item'
    ],
    computed: {

        ...mapState('job', ['jobs']),
    },
    beforeMount() {
        
        let vm = this

        vm.loadDataFromItem()
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            if (data.updated) {

                vm.updateJob(data.updated)
                vm.readonly = true
            }
            // si se actualizó el estado
            else if (data.status) {

                vm.toggleStatusJob(
                    {
                        id: vm.form.id,
                        status: data.status
                    }
                )
                vm.form.status = data.status
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
        status: function(value) {

            const formStatus = this.form.status == 'Activo'? true : false
            
            if (value !== formStatus) {
                this.toogleStatus()
            }
        }
    },
    methods: {

        ...mapMutations('job', ['updateJob', 'toggleStatusJob']),
        loadDataFromItem() {

            let vm = this

            vm.form.id =            vm.item.id
            vm.form.name =          vm.item.name
            vm.form.description =   vm.item.description
            vm.form.status =        vm.item.status
            if (vm.item.status == 'Activo') {
                vm.status = true
            }
        },
        closeDetails() {

            this.$emit('hideDetails')
        },
        toUpdate() {

            let vm =this
            vm.readonly = false
        },
        cancel() {

            let vm =this
            vm.readonly = true
            
            if (vm.item) {
            
                vm.loadDataFromItem()

            }
        },
        onSubmit() {

            let vm = this

            const data = {
                id: vm.form.id,
                name: vm.form.name,
                description: vm.form.description
            }

            vm.sendRequest('patch', 'job', data)
                
        },
        toogleStatus() {

            this.sendRequest(
                'post',
                'job/toggle-status',
                {
                    id: this.form.id
                }
            )
        }
    },
}
</script>