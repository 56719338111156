import Axios from "axios"

const mixin = {

    data() {
        return {
            tag: "input",
            loading: false,
            appPath: 'http://localhost:3000/api/',
            response: null,
            errors: null
        }
    },

    methods: {
    
        async sendRequest(method,route, data) {

            let vm = this
    
            vm.tag = 'button'
            vm.loading = true


            // aram el tipo de consulta
            const options = {
                method,
                url: vm.appPath + route,
                data,
                headers:
                    {
                        'Content-Type': 'application/json',
                        'auth-token': sessionStorage.getItem('user-token')
                    }
            }
            
            await Axios(options)
                .then(
                    (res) => {
                        vm.response = res.data
                    }
                ).catch(
                    (e) => {

                        if (e) {

                            let messages = ''

                            if (e.response.data.messages) {

                                e.response.data.messages.forEach(element => {
                                    messages += element.message + "\n"
                                });

                                e.response.data.message += "\n" + messages
                            }
                            vm.errors = e.response.data
                        }
                    }
                ).finally(
                    () => {
                        vm.tag = 'input'
                        vm.loading = false
                    }
                )
    
        }

    }
}

export default (

    mixin
)