<template>
    
    <div class="card">

        <div class="card-header header-fhc">
            <div class="card-header-title pading-left text-white">
                <p>Nueva unidad de negocio</p>
            </div>
        </div>

        <div class="card-content">

            <b-notification v-if="loading" :closable="false">
                <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
            </b-notification>

            <form @submit.prevent="onSubmit">

                <b-field label="nombre">
                    <b-input v-model="form.name" required></b-input>
                </b-field>

                <b-field label="Descripción">
                    <b-input v-model="form.description" required></b-input>
                </b-field>

                <br>

                <div class="buttons">

                    <b-button
                        :tag="tag"
                        native-type="submit"
                        type="is-primary"
                        value="Guardar"
                        :loading="loading"
                        expanded/>

                    <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>

                </div>

            </form>
        </div>
    </div>
    
</template>

<script>
import { mapMutations } from 'vuex'
import mixins from '../../mixins/mixins'
export default {
    data() {
        return {
            loading: false,
            form: {
                name: null,
                description: null
            }
        }
    },
    mixins: [
        mixins
    ],
    beforeMount() {
         
        this.changeCancelModalBranchOffice(false)
    },
     watch: {

        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // agrega las prestaciones a los datos
            if (data.branchOffice) {

                vm.addBranchOffice(data.branchOffice)
                vm.changeNewModalBranchOffice(false)
                
            }

            this.changeCancelModalBranchOffice(true)
        },
        errors(data) {
        
            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        }

        
    },
    methods: {

        ...mapMutations('branchOffice', ['changeCancelModalBranchOffice', 'changeNewModalBranchOffice', 'addBranchOffice']),
        cancel() {

            this.changeCancelModalBranchOffice(true)
            this.changeNewModalBranchOffice(false)
        },
        onSubmit() {

            let vm = this

            const data = {
                clientId: sessionStorage.getItem('clientId'),
                name: vm.form.name,
                description: vm.form.description
            }

            vm.sendRequest('post', 'branch-office', data)
        }
    }
}
</script>