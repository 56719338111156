<template>

    <div class="card" >

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>

                <form @submit.prevent="onSubmit">

                    <b-field grouped>

                        <b-field label="De" expanded>
                            <b-input type="number" v-model="form.initialYear" required></b-input>
                        </b-field>
                        
                        <b-field label="A" expanded>
                            <b-input type="number" v-model="form.finalYear" required></b-input>
                        </b-field>
                        
                        <b-field label="Días" expanded>
                            <b-input type="number" v-model="form.days" required></b-input>
                        </b-field>

                    </b-field>
                            
                    <!-- botonera para guardar registro -->
                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel('insert')" expanded>Cancelar</b-button>

                    </div>

                </form>
            </section>

        </div>
    </div>
    
</template>

<script>
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            form: {
                id: null,
                initialYear: null,
                finalYear: null,
                days: null
            }
        }
    },
    props: [
        'benefitId',
    ],
    mixins: [
        mixins
    ],
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // si sagrega un día
            if (data.vacationDay) {
                
                vm.$emit('addData', data.vacationDay)
                
            }
        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        }
    },
    methods: {
        
        cancel(type) {
            
            this.$emit('hideForm',type)
        },
        onSubmit() {

            let vm = this

            vm.loading = true
            vm.tag = 'button'

            const data = {
                benefitId: vm.benefitId,
                initialYear: vm.form.initialYear,
                finalYear: vm.form.finalYear,
                days: vm.form.days

            }

            vm.sendRequest('post', 'vacation-day', data)
                
        }
    },
}
</script>