<template>

    <div class="card">

        <div class="card-header header-fhc">

            <div class="card-header-title pading-left text-white">
                <p>Nueva Estructura</p>
            </div>
        </div>

        <div class="card-content">

            <section>

                <b-notification v-if="loading" :closable="false">
                    <b-loading :is-full-page="true" :active.sync="loading" :can-cancel="false"></b-loading>
                </b-notification>
                
                <form @submit.prevent="onSubmit">

                    <b-field label="Nombre de la estructura">
                        <b-input v-model="name" required></b-input>
                    </b-field>

                    <b-field label="Descripción">
                        <b-input v-model="description" required></b-input>
                    </b-field>

                    <br>
                    <div class="buttons">

                        <b-button
                            :tag="tag"
                            native-type="submit"
                            type="is-primary"
                            value="Guardar"
                            :loading="loading"
                            expanded/>

                        <b-button v-if="!loading" type="is-success" @click="cancel" expanded>Cancelar</b-button>
                    </div>
                </form>
            </section>

        </div>
    </div>
    
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import mixins from '../../mixins/mixins'

export default {
    data() {
        return {
            status: null,
            name: null,
            description: null,
        }
    },
    mixins: [
        mixins
    ],
    beforeMount() {

        let vm = this


        vm.changeCancelModalStructure(false)
    },
    computed: {

        ...mapState('structure', ['structures']),
    },
    watch: {
        response(data) {
            
            let vm = this

            // mandamos mensaje
            vm.$buefy.toast.open({
                duration: 3000,
                message: data.message,
                type: 'is-success'
            })
            
            // sí regresa una estructura nueva
            if (data.structure) {

                vm.addStructure(data.structure)
                vm.cancel()
                    
            }

        },
        errors(data) {

            this.$buefy.toast.open(
                {
                    duration: 5000,
                    message: data.message,
                    type: 'is-danger'
                }
            )
        },
    },
    methods: {
        
        ...mapMutations('structure', ['changeNewModalStructure', 'changeCancelModalStructure', 'addStructure']),
        cancel() {

            this.changeNewModalStructure(false)
            this.changeCancelModalStructure(true)
            this.options = []
        },
        onSubmit() {

            let vm = this

            const data = {
                clientId: sessionStorage.getItem('clientId'),
                name: vm.name,
                description: vm.description
            }

            vm.sendRequest('post', 'structure', data)

        }
    },
}
</script>